import React, { useState } from 'react';
import { Liked } from '../../../types/liked.types';
import { Inventory } from '../../../types/item.types';
import styles from './LikedItem.module.css';
import forma3 from '../../../assets/images/forma2.jpg';
import { GoStarFill } from 'react-icons/go';
import { connect } from 'react-redux';
import { unlikeItem } from '../../../redux/reducers/liked-reducer';
import { NavLink } from 'react-router-dom';
import { itemApi } from '../../../api/api';
import { addToCart } from '../../../redux/reducers/cart-reducer';
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';

interface LikedItemProps {
    item: Liked;
    unlikeItem: (id: number) => void;
    addToCart: (product_id: number, color: string, size: string, quantity: number) => void;
}

const LikedItem = (props: LikedItemProps) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [colors, setColors] = useState<Inventory[]>([]);
    const [selectedColor, setSelectedColor] = useState<string | null>(null);
    const [selectedSize, setSelectedSize] = useState<string | null>(null);
    const [isFetching, setIsFetching] = useState(false);
    const [errorMessage, setErrorMessage] = useState<string>('');

    const handleUnlikeItem = () => {
        props.unlikeItem(props.item.product_id);
    };

    const handleOpenModal = async (id: number) => {
        setIsModalOpen(true);
        setIsFetching(true);
        try {
            const response = await itemApi.getItem(id);
            setColors(response.inventory);
        } catch (error) {
            console.error('Error fetching item data:', error);
        } finally {
            setIsFetching(false);
        }
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
        setSelectedColor(null);
        setSelectedSize(null);
        setErrorMessage('');
    };

    const handleBuy = async (id: number) => {
        if (!selectedColor) {
            setErrorMessage('Будь ласка, виберіть колір!');
            return;
        }
        if (!selectedSize) {
            setErrorMessage('Будь ласка, виберіть розмір!');
            return;
        }

        try {
            await props.addToCart(id, selectedColor, selectedSize, 1);
            alert('Товар додано до кошика!');
            handleCloseModal();
        } catch (error) {
            console.error('Помилка під час додавання товару до кошика:', error);
        }
    };

    return (
        <article className={styles.container}>
            {/* Modal */}
            {isModalOpen && (
                <div className={styles.back}>
                    <div className={styles.modalContent}>
                        {isFetching ? (
                            <p>Завантаження...</p>
                        ) : (
                            <div>
                                <FormControl fullWidth margin="normal">
                                    <InputLabel id="color-select-label">Виберіть колір</InputLabel>
                                    <Select
                                        labelId="color-select-label"
                                        value={selectedColor || ''}
                                        onChange={(e) => {
                                            setSelectedColor(e.target.value);
                                            setSelectedSize(null); // Reset size when color changes
                                            setErrorMessage('');
                                        }}
                                    >
                                        {colors.map((color) => (
                                            <MenuItem key={color.color} value={color.color}>
                                                {color.color}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>

                                {selectedColor && (
                                    <FormControl fullWidth margin="normal">
                                        <InputLabel id="size-select-label">Виберіть розмір</InputLabel>
                                        <Select
                                            labelId="size-select-label"
                                            value={selectedSize || ''}
                                            onChange={(e) => {
                                                setSelectedSize(e.target.value);
                                                setErrorMessage('');
                                            }}
                                        >
                                            {colors
                                                .find((color) => color.color === selectedColor)
                                                ?.sizes.map((size) => (
                                                    <MenuItem key={size.size} value={size.size}>
                                                        {size.size} (на складі: {size.stock})
                                                    </MenuItem>
                                                ))}
                                        </Select>
                                    </FormControl>
                                )}
                            </div>
                        )}
                        {errorMessage && <p className={styles.error}>{errorMessage}</p>}
                        <div className={styles.buttons}>
                            <button onClick={() => handleBuy(props.item.product_id)} className={styles.buyNowButton}>
                                Купити
                            </button>
                            <button onClick={handleCloseModal} className={styles.closeButton}>
                                x
                            </button>
                        </div>
                    </div>
                </div>
            )}

            {/* Product Card */}
            <NavLink to={`/product/${props.item.product_id}`} className={styles.link}>
                <div className={styles.imageContainer}>
                    <img
                        src={props.item.media[0]?.media_url || forma3}
                        alt={props.item.name}
                        className={styles.image}
                    />
                </div>
            </NavLink>
            <div className={styles.contentContainer}>
                <GoStarFill className={styles.star} onClick={handleUnlikeItem} />
                <p className={styles.title}>{props.item.name}</p>
                <button className={styles.button} onClick={() => handleOpenModal(props.item.product_id)}>
                    Купити
                </button>
            </div>
        </article>
    );
};

const mapDispatchToProps = {
    unlikeItem,
    addToCart,
};

export default connect(null, mapDispatchToProps)(LikedItem);
