import React, { useEffect, useState } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import AdminSidebar from '../components/UI/AdminSidebar/AdminSidebar';
import styles from './AdminLayout.module.css';
import { authApi } from '../api/api';

const AdminLayout = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [fetching, setFetching] = useState(true);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false); // Sidebar toggle state
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768); // Track screen size

  const toggleSidebar = () => setIsSidebarOpen((prev) => !prev);

  // Handle screen size changes for mobile
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  //Zakomentyty
  useEffect(() => {
    setFetching(true);
    authApi
      .isAdmin()
      .then((data) => {
        if (!data.isAdmin) {
          navigate('/login');
        }
      })
      .catch((error) => {
        if (error.response.status === 403 || error.response.status === 401) {
          navigate('/login');
        } else {
          console.log(error);
        }
      })
      .finally(() => {
        setFetching(false);
      });
  }, [navigate]);
  useEffect(() => {
    // Check if the path includes the word "admin"
    if (location.pathname.includes("admin")) {
      document.body.style.overflowY = "hidden"; // Disable vertical scrolling
    } else {
      document.body.style.overflowY = "auto"; // Enable scrolling
    }

    // Clean up to reset style on component unmount
    return () => {
      document.body.style.overflowY = "auto";
    };
  }, [location]);
  if (fetching) {
    return <div></div>;
  }

  return (
    <div className={styles.adminPanel}>
      {/* Toggle button for mobile only */}
      {isMobile && (
        <button
          className={styles.toggleButton}
          onClick={toggleSidebar}
          aria-label="Toggle Sidebar"
        >
          ☰
        </button>
      )}

      {/* Sidebar (always visible on larger screens, toggleable with animation on mobile) */}
      <div
        className={`${styles.sidebar} ${isSidebarOpen ? styles.sidebarOpen : ''}`}
      >
        <AdminSidebar />
      </div>

      <div className={styles.content}>
        <Outlet />
      </div>
    </div>
  );
};

export default AdminLayout;
