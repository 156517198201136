import React from 'react'
import styles from './Footer.module.css'
import logo from '../../../assets/icons/logo.svg'
import { FaFacebook, FaInstagram } from 'react-icons/fa6'
import { connect } from 'react-redux'
import { RootState } from '../../../types/store.types'
import { getCategories } from '../../../redux/reducers/category-reducer'
import { CategoryType } from '../../../types/category.types'
import { useEffect } from 'react'
import { NavLink } from 'react-router-dom'

interface FooterProps {
    getCategories: () => void,
    categories: CategoryType[]
    isFetching: boolean
}
const Footer = (props: FooterProps) => {
    useEffect(() => {
        props.getCategories()
    }, [])
    return (
        <footer className={styles.footer}>
            <div className={styles.footerContainer}>
                <div className={styles.website}>
                    <img src={logo} alt="logo" className={styles.logo} />
                    <p className={styles.number}>+123 456 789</p>
                    <div className={styles.icons}>
                        <FaFacebook className={styles.icon} />
                        <FaInstagram className={styles.icon} />
                    </div>
                    <p className={styles.copyright}>2024®</p>
                </div>
                <div className={styles.links}>
                    <div className={styles.categoryLinks}>
                        <h3 className={styles.title}>Категорії: </h3>
                        <div className={styles.linksContainer}>
                            {!props.isFetching ? (
                                props.categories.map((category: CategoryType) => {
                                    return (
                                        <NavLink to={`/category/${category.category_id}`} key={category.category_id} className={styles.link}>
                                            {category.name}
                                        </NavLink>
                                    );
                                })
                            ) : (
                                <p>Завантаження...</p>
                            )}
                        </div>
                    </div>
                    <div className={styles.infoLinks}>
                        <h3 className={styles.title}>Інформація</h3>
                        <div className={styles.linksContainer}>
                            <a href="/" className={styles.link}>Про нас</a>
                            <a href="/" className={styles.link}>Доставка</a>
                            <a href="/" className={styles.link}>Оплата</a>
                            <a href="/" className={styles.link}>Повернення</a>
                        </div>
                    </div>
                    <div className={styles.contactLinks}>
                        <h3 className={styles.title}>Контакти</h3>
                        <div className={styles.linksContainer}>
                            <a href="/" className={styles.link}>+123 456 789</a>
                            <a href="/" className={styles.link}>info@tacticalform.com</a>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    )
}

let mapStateToProps = (state: RootState) => {
    return {
        categories: state.category.categories,
        isFetching: state.category.isFetching
    }
}
let mapDispatchToProps = {
    getCategories
}
export default connect(mapStateToProps, mapDispatchToProps)(Footer)
