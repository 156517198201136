import React, { useEffect, useState } from 'react';
import { Slider, TextField, Box, Checkbox, FormControlLabel } from '@mui/material';
import styles from './Filter.module.css';
import { useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import { RootState } from '../../../types/store.types';
import { getItemsByCategory } from '../../../redux/reducers/item-reducer';
import { ItemsByCategory } from '../../../types/item.types';
import { colorApi } from '../../../api/api';

const sizeOptions = ['S', 'M', 'L', 'XL', 'XXL'];

interface FilterProps {
  onFilterHandler: (priceRange: number[], selectedColors: string[], selectedSizes: string[]) => void;
  categoryItems: ItemsByCategory;
  isFetching: boolean;
  getItemsByCategory: (category: number, page: number) => void;
}

const Filter = (props: FilterProps) => {
  const [priceRange, setPriceRange] = useState([0, 10000]);
  const [tempPriceRange, setTempPriceRange] = useState([0, 10000]);
  const [selectedColors, setSelectedColors] = useState<string[]>([]);
  const [selectedSizes, setSelectedSizes] = useState<string[]>([]);
  const [colors, setColors] = useState<{ color: string; color_hex: string | null }[]>([]); // New state
  const [sizes, setSizes] = useState<string[]>([]);
  const { id } = useParams<{ id: string }>();

  useEffect(() => {
    handleClearFilters();
  }, [id]);

  useEffect(() => {
    fetchColors();
    fetchSizes();
  }, []);

  const fetchColors = async () => {
    try {
      const response = await colorApi.getColors();
      const fetchedColors = response.colors.filter((color: any) => color.color !== '');
      setColors(fetchedColors);
    } catch (error) {
      console.error('Failed to fetch colors:', error);
    }
  };
  const fetchSizes = async () => {
    try {
      const response = await colorApi.getSizes();
      console.log('Sizes response:', response.sizes); // Debug
      setSizes(Array.isArray(response.sizes) ? response.sizes : []); // Ensure it's an array
    } catch (error) {
      console.error('Failed to fetch sizes:', error);
      setSizes([]); // Default to an empty array if the API fails
    }
  };
  const handleClearFilters = () => {
    setPriceRange([0, 10000]);
    setTempPriceRange([0, 10000]);
    setSelectedColors([]);
    setSelectedSizes([]);
  };

  const handleSliderChangeCommitted = (event: any, newValue: number | number[]) => {
    setPriceRange(newValue as number[]);
  };

  useEffect(() => {
    props.onFilterHandler(priceRange, selectedColors, selectedSizes);
  }, [priceRange, selectedColors, selectedSizes]);

  const handleInputChange = (index: number, value: string) => {
    const newTempPriceRange = [...tempPriceRange];
    newTempPriceRange[index] = Number(value);
    setTempPriceRange(newTempPriceRange);
  };

  const handleBlur = (index: number) => {
    if (tempPriceRange[index] < 0) {
      handleInputChange(index, '0');
    }
    setPriceRange(tempPriceRange);
  };

  const handleSizeSelect = (size: string) => {
    setSelectedSizes((prevSelectedSizes) =>
      prevSelectedSizes.includes(size)
        ? prevSelectedSizes.filter((s) => s !== size)
        : [...prevSelectedSizes, size]
    );
  };

  const handleColorSelect = (color: string) => {
    setSelectedColors((prevSelectedColors) =>
      prevSelectedColors.includes(color)
        ? prevSelectedColors.filter((c) => c !== color)
        : [...prevSelectedColors, color]
    );
  };

  return (
    <div className={styles.filterContainer}>
      {/* Price Filter */}
      <div className={styles.filterItem}>
        <p className={styles.filterItemTitle}>Ціна</p>
        <Box sx={{ width: '100%' }}>
          <Slider
            value={tempPriceRange}
            onChange={(event, newValue) => setTempPriceRange(newValue as number[])}
            onChangeCommitted={handleSliderChangeCommitted}
            valueLabelDisplay="auto"
            min={0}
            max={10000}
            sx={{
              color: '#343a40',
              height: 8,
              '& .MuiSlider-thumb': {
                height: 20,
                width: 20,
                backgroundColor: '#ffffff',
                '&:hover': { boxShadow: 'inherit' },
              },
              '& .MuiSlider-track': { borderRadius: 5 },
              '& .MuiSlider-rail': { borderRadius: 5 },
            }}
          />
        </Box>
        <div className={styles.inputContainer}>
          <TextField
            type="number"
            value={tempPriceRange[0]}
            onChange={(e) => handleInputChange(0, e.target.value)}
            onBlur={() => handleBlur(0)}
            variant="outlined"
            size="small"
            inputProps={{ min: 0 }}
            sx={{ marginRight: '5px', width: '100px' }}
          />
          <span className={styles.rangeSeparator}> - </span>
          <TextField
            type="number"
            value={tempPriceRange[1]}
            onChange={(e) => handleInputChange(1, e.target.value)}
            onBlur={() => handleBlur(1)}
            variant="outlined"
            size="small"
            inputProps={{ min: 0 }}
            sx={{ marginLeft: '5px', width: '100px' }}
          />
        </div>
      </div>

      {/* Color Filter */}
      <div className={styles.filterItem}>
      <p className={styles.filterItemTitle}>Колір</p>
      <div className={styles.colorContainer}>
        {colors.map(({ color, color_hex }, index) => (
          <button
            key={index}
            className={`${styles.colorButton} ${selectedColors.includes(color) ? styles.selectedColorButton : ''}`}
            onClick={() => handleColorSelect(color)}
          >
            {color}
          </button>
        ))}
      </div>
    </div>


      {/* Size Filter */}
      <div className={styles.filterItem}>
        <p className={styles.filterItemTitle}>Розмір</p>
        <div className={styles.sizeContainer}>
          {sizes.length > 0 ? (
            sizes.map((size) => (
              <FormControlLabel
                key={size}
                control={
                  <Checkbox
                    checked={selectedSizes.includes(size)}
                    onChange={() => handleSizeSelect(size)}
                  />
                }
                label={size}
              />
            ))
          ) : (
            <p>Loading sizes...</p>
          )}
        </div>
      </div>
      <button onClick={handleClearFilters} className={styles.clearFiltersBtn}>Видалити фільтри</button>
    </div>
  );
};

let mapStateToProps = (state: RootState) => ({
  categoryItems: state.item.categoryItems,
  isFetching: state.item.isFetching,
});

let mapDispatchToProps = { getItemsByCategory };

export default connect(mapStateToProps, mapDispatchToProps)(Filter);
