import React, { useEffect } from 'react'
import styles from './CartModal.module.css'
import { connect } from 'react-redux'
import { RootState } from '../../../types/store.types'
import { getCart, removeFromCart, updateCart } from '../../../redux/reducers/cart-reducer'
import { CartType } from '../../../types/cart.types'
import { Skeleton } from '@mui/material'
import { IoClose } from "react-icons/io5";
import { FaMinus, FaPlus } from 'react-icons/fa6'
import { RiDeleteBin6Line } from "react-icons/ri";
import AlertMessage from '../AlertMessage/AlertMessage'
import { GoArrowLeft } from "react-icons/go";
import { useNavigate } from 'react-router-dom'
import { useMediaQuery } from '@uidotdev/usehooks'

interface CartModalProps {
    cartItems: CartType[]
    isFetching: boolean
    getCart: () => void
    onCloseModal: () => void
    removeFromCart: (product_id: number, color: string, size: string) => void
    updateCart: (product_id: number, color: string, size: string, quantity: number) => void
}

const CartModal = (props: CartModalProps) => {
    const navigate = useNavigate();
    const isMobile = useMediaQuery('(max-width: 624px)');
    const [isSuccsessDelete, setIsSuccsessDelete] = React.useState(false);
    const [isErrorDelete, setIsErrorDelete] = React.useState(false);
    const [isSuccsessUpdate, setIsSuccsessUpdate] = React.useState(false);
    const [isErrorUpdate, setIsErrorUpdate] = React.useState(false);

    useEffect(() => {
        props.getCart();
    }, [])

    const handleCheckout = () => {
        navigate('/checkout');
        props.onCloseModal();
    }

    const onCloseModalHandler = () => {
        props.onCloseModal();
    }

    const onAddItemHandler = async (product_id: number, color: string, size: string, quantity: number) => {

        try {
            await props.updateCart(product_id, color, size, quantity + 1);
            setIsSuccsessUpdate(true);
            setTimeout(() => {
                setIsSuccsessUpdate(false);
            }, 3000);
        }
        catch (e) {
            setIsErrorUpdate(true);
            setTimeout(() => {
                setIsErrorUpdate(false);
            }, 3000);
        }
    }
    const onSubtractItemHandler = async (product_id: number, color: string, size: string, quantity: number) => {
        if (quantity === 1) {
            return;
        }
        try {
            await props.updateCart(product_id, color, size, quantity - 1);
            setIsSuccsessUpdate(true);
            setTimeout(() => {
                setIsSuccsessUpdate(false);
            }, 3000);
        }
        catch (e) {
            setIsErrorUpdate(true);
            setTimeout(() => {
                setIsErrorUpdate(false);
            }, 3000);
        }
    }

    const onRemoveItemHandler = async (product_id: number, color: string, size: string) => {
        try {
            await props.removeFromCart(product_id, color, size);
            setIsSuccsessDelete(true);
            setTimeout(() => {
                setIsSuccsessDelete(false);
            }, 3000);
        }
        catch (e) {
            setIsErrorDelete(true);
            setTimeout(() => {
                setIsErrorDelete(false);
            }, 3000);
        }
    }
    return (
        <div className={styles.background}>
            {isSuccsessUpdate ? <AlertMessage message='Кількість товару успішно змінено' backgroundColor='#d4edda' /> : isErrorUpdate ? <AlertMessage message='Помилка зміни кількості товару' backgroundColor='#f8d7da' /> : null}
            {isSuccsessDelete ? <AlertMessage message='Товар успішно видалено з кошика' backgroundColor='#d4edda' /> : isErrorDelete ? <AlertMessage message='Помилка видалення товару з кошика' backgroundColor='#f8d7da' /> : null}
            {isMobile ? <IoClose className={styles.closeIcon} onClick={onCloseModalHandler} /> : null}
            {
                props.isFetching ? (
                    <Skeleton
                        animation='wave'
                        variant='rectangular'
                        sx={{ bgcolor: '#f1f3f5' }}
                        className={styles.loadingSkeleton}
                        width={800}
                        height={700}
                    />
                ) : (
                    props.cartItems.length === 0 ?
                        <div className={styles.emptyCartContainer}>
                            {!isMobile?<IoClose className={styles.closeIcon} onClick={onCloseModalHandler} />:null}
                            <div className={styles.emptyCartIcon}>
                                <svg width="64" height="64" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M4 4h2l2.68 12.39a3 3 0 0 0 2.94 2.61h7.72a3 3 0 0 0 2.94-2.61L21 8H6" stroke="#ADB5BD" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                    <circle cx="9" cy="21" r="1" fill="#ADB5BD" />
                                    <circle cx="18" cy="21" r="1" fill="#ADB5BD" />
                                    <path d="M10 10c.5-1.5 2.5-1.5 3 0" stroke="#ADB5BD" strokeLinecap="round" />
                                    <path d="M7 6s-.5-.5-1 0" stroke="#ADB5BD" strokeLinecap="round" />
                                    <path d="M13 10c-.5-1.5-2.5-1.5-3 0" stroke="#ADB5BD" strokeLinecap="round" />
                                </svg>
                            </div>
                            <p className={styles.emptyCartText}>Кошик порожній</p>
                            <p className={styles.emptyCartSubtitle}>Додайте товари до кошика, щоб продовжити покупки</p>
                            <button className={styles.continueShoppingButton} onClick={onCloseModalHandler}>Продовжити покупки</button>
                        </div>
                        :
                        <div className={styles.container}>
                            <IoClose className={styles.closeIcon} onClick={onCloseModalHandler} />
                            <table className={styles.table}>
                                <thead>
                                    <tr>
                                        <th className={styles.mainTitle}>Кошик</th>
                                        <th className={styles.sideText}>Кількість</th>
                                        <th className={styles.priceText}>Ціна</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        props.cartItems.map((item, index) => (
                                            <tr key={index} className={styles.tableRow}>
                                                <td className={styles.mainContainer}>
                                                    <RiDeleteBin6Line className={styles.deleteIcon} onClick={() => onRemoveItemHandler(item.product_id, item.color, item.size)} />
                                                    <img src={item.media[0].media_url} alt={item.name} className={styles.image} />
                                                    <div className={styles.itemInfo}>
                                                        <p className={styles.itemName}>{item.name}</p>
                                                        <p className={styles.itemSize}>Розмір: {item.size}</p>
                                                        <div className={styles.colorTextContainer}>
                                                            <p className={styles.itemColor}>Колір:</p>
                                                            <p className={styles.itemColor}>{item.color}</p>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td className={styles.sideDataCell}>
                                                    <div className={styles.quantityContainer}>
                                                        <div className={styles.quantityButton}><FaMinus className={styles.icon} onClick={() =>
                                                            onSubtractItemHandler(item.product_id, item.color, item.size, item.quantity)} />
                                                        </div>
                                                        <p className={styles.quantity}>{item.quantity}</p>
                                                        <div className={styles.quantityButton}><FaPlus className={styles.icon}
                                                            onClick={() => onAddItemHandler(item.product_id, item.color, item.size, item.quantity)}
                                                        /></div>
                                                    </div>
                                                </td>
                                                <td className={styles.priceDataCell}>{Number(item.price) * item.quantity}</td>
                                            </tr>
                                        ))
                                    }
                                </tbody>
                            </table>
                            <div className={styles.totalContainer}>
                                <p className={styles.totalText}>Загальна сума: <span className={styles.totalPrice}>{props.cartItems.reduce((acc, item) => acc + Number(item.price) * item.quantity, 0)} грн</span></p>
                            </div>
                            <div className={styles.checkoutContainer}>
                                <div className={styles.arrowContainer}>
                                    <GoArrowLeft className={styles.arrowIcon} onClick={onCloseModalHandler} />
                                    <p className={styles.returnText}>Повернутись до покупок</p>
                                </div>
                                <button className={styles.checkoutButton} onClick={handleCheckout}>Оформити замовлення</button>
                            </div>
                        </div>
                )
            }
        </div>
    )

}

let mapStateToProps = (state: RootState) => {
    return {
        cartItems: state.cart.cart,
        isFetching: state.cart.isFetching
    }
}

let mapDispatchToProps = {
    getCart,
    removeFromCart,
    updateCart
}

export default connect(mapStateToProps, mapDispatchToProps)(CartModal)
