import React from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import styles from './AdminSidebar.module.css';
import { FaHome } from "react-icons/fa";
import { IoIosLogOut } from "react-icons/io";
import { logout } from '../../../redux/reducers/auth-reducer';
import { connect } from 'react-redux';

interface AdminSidebarProps {
  logout: () => void;
}

const AdminSidebar = (props:AdminSidebarProps) => {
  const navigate = useNavigate();
  const handleLogout = () => {
    props.logout();
    localStorage.removeItem('token');
    window.location.reload();
  }
  return (
    <div className={styles.sidebar}>
      <FaHome className={styles.homeIcon} onClick={()=>navigate('/')}/>
      <IoIosLogOut className={styles.logoutIcon} onClick={handleLogout}/>
      <p className={styles.title}>Адмін Панель</p>
      <ul>
        <li>
          <NavLink to="/admin/products" className={({ isActive }) => isActive ? styles.activeLink : undefined}>
            Продукти
          </NavLink>
        </li>
        <li>
          <NavLink to="/admin/categories" className={({ isActive }) => isActive ? styles.activeLink : undefined}>
            Категорії
          </NavLink>
        </li>
        <li>
          <NavLink to="/admin/orders" className={({ isActive }) => isActive ? styles.activeLink : undefined}>
            Замовлення
          </NavLink>
        </li>
        {/* <li>
          <NavLink to="/admin/contacts" className={({ isActive }) => isActive ? styles.activeLink : undefined}>
            Контакти
          </NavLink>
        </li> */}
      </ul>
    </div>
  );
};


let mapStateToProps = (state: any) => {
  return {}
}

let mapDispatchToProps = {
  logout,
}
export default connect(mapStateToProps, mapDispatchToProps)(AdminSidebar);
