import { authApi } from "../../api/api";
export const authReducer = (state = {}, action: any) => {
    switch (action.type) {
        default:
            return state;
    }
}

export const authActions = {
    // Action creators
}

export const login = (email: string, password: string) => {
    return async (dispatch: any) => {
       try {
           const data = await authApi.login(email, password);
              localStorage.setItem('token', data.token);
         } catch (e) {
             console.log(e);
         }
    }
}
export const logout = () => {
    return async (dispatch: any) => {
        authApi.logout().then(() => {
            localStorage.removeItem('token');
        })
    }
}
export const isAdmin = () => {
    return async (dispatch: any) => {
        authApi.isAdmin().then((data) => {
            console.log(data);
        })
    }
}