import React from 'react';
import styles from './AboutUs.module.css';
import aboutUs1 from '../../../assets/images/aboutUs1.jpg';
import aboutUs2 from '../../../assets/images/aboutUs2.jpg';
import aboutUs3 from '../../../assets/images/aboutUs3.jpg';
import { useMediaQuery } from '@uidotdev/usehooks';

const AboutUs = () => {
    const isTablet = useMediaQuery('(min-width: 500px) and (max-width: 700px)');
    const isMobile = useMediaQuery('(max-width: 500px)');
    console.log(isTablet);
    return (
        <section className={styles.about}>
            <div className={styles.container}>
                <div className={styles.aboutUs}>
                    <h2 className={styles.title}>Про нас</h2>
                    <p className={styles.description}>
                        Ми новітній інтернет магазин, який виробляє свій тактичний одяг для військових і тактичних цілей.
                        Ми новітній інтернет магазин, який виробляє свій тактичний одяг для військових і тактичних цілей. Ми новітній інтернет магазин, який виробляє свій тактичний одяг для військових і тактичних цілей
                    </p>
                </div>
                <div className={styles.aboutUsImages}>
                    {!isTablet ? (
                        <>
                       {isMobile ? (
                        <>
                        <img src={aboutUs1} alt="aboutUs1" className={styles.aboutUsImage} />
                        </>
                       ): (
                        <>
                        <img src={aboutUs1} alt="aboutUs1" className={styles.aboutUsImage} />
                        <img src={aboutUs2} alt="aboutUs2" className={styles.aboutUsImage} />
                        <img src={aboutUs3} alt="aboutUs3" className={styles.aboutUsImage} />
                        </>
                       )}
                        </>
                    ): (
                        <>
                        <img src={aboutUs1} alt="aboutUs1" className={styles.aboutUsImage} />
                        <img src={aboutUs2} alt="aboutUs2" className={styles.aboutUsImage} />
                        </>
                    )}
                    
                </div>
            </div>
        </section>
    );
};

export default AboutUs;
