import React from 'react'
import styles from './NotFound.module.css'
import { useNavigate } from 'react-router-dom'
const NotFound = () => {
    const navigate = useNavigate()
    const goHome = () => {
        navigate('/')
    }
    return (
        <main className={styles.container}>
            <div className={styles.content}>
                <h2 className={styles.title}>404 - Сторінка не знайдена</h2>
                <p className={styles.description}>Вибачте, але сторінка, яку ви шукали, не існує.</p>
                <button className={styles.button} onClick={goHome}>Повернутися додому</button>
            </div>
        </main>
    )
}

export default NotFound
