import { CategoryType } from '../../types/category.types'; // Assuming CategoryType is defined here
import { categoryApi } from '../../api/api';               // Assuming categoryApi is properly typed

// Action types
const SET_CATEGORIES = 'SET_CATEGORIES';
const SET_IS_FETCHING = 'SET_IS_FETCHING';

// Initial state type
interface StateType {
  categories: CategoryType[];
  isFetching: boolean;
}

// Define initial state
const initialState: StateType = {
  categories: [],
  isFetching: false
};

// Define action type with generics for payload type safety
interface ActionType<T = any> {
  type: string;
  payload: T;
}

// Reducer with full type annotations
export const categoryReducer = (state: StateType = initialState, action: ActionType<CategoryType[] | boolean>): StateType => {
  switch (action.type) {
    case SET_CATEGORIES:
      return {
        ...state,
        categories: action.payload as CategoryType[] // Ensure payload is typed as CategoryType array
      };
    case SET_IS_FETCHING:
      return {
        ...state,
        isFetching: action.payload as boolean
      };
    default:
      return state;
  }
};

// Action creators with explicit types
const setCategories = (categories: CategoryType[]): ActionType<CategoryType[]> => ({
  type: SET_CATEGORIES,
  payload: categories
});

const setIsFetching = (isFetching: boolean): ActionType<boolean> => ({
  type: SET_IS_FETCHING,
  payload: isFetching
});

// Async action with fully typed dispatch and return value
export const getCategories = () => async (dispatch: (action: ActionType<CategoryType[] | boolean>) => void): Promise<void> => {
  dispatch(setIsFetching(true));
  try {
    let data: CategoryType[] = await categoryApi.getCategories(); // Assuming categoryApi.getCategories() returns CategoryType[]
    data = data.filter((category:CategoryType)=>category.name!=='Невідоме')
    dispatch(setCategories(data));
  } finally {
    dispatch(setIsFetching(false));
  }
};

export const deleteCategory = (id: number) => async (dispatch: (action: ActionType<boolean>) => void): Promise<void> => {
  dispatch(setIsFetching(true));
  try {
    await categoryApi.deleteCategory(id);
    dispatch(setIsFetching(true));
  } finally {
    dispatch(setIsFetching(false));
  }
}

export const addCategory = (name: string) => async (dispatch: (action: ActionType<boolean>) => void): Promise<void> => {
  dispatch(setIsFetching(true));
  try {
    await categoryApi.addCategory(name);
    dispatch(setIsFetching(true));
  } finally {
    dispatch(setIsFetching(false));
  }
}

export const updateCategory = (id: number, name: string) => async (dispatch: (action: ActionType<boolean>) => void): Promise<void> => {
  dispatch(setIsFetching(true));
  try {
    await categoryApi.updateCategory(id, name);
    dispatch(setIsFetching(true));
  } finally {
    dispatch(setIsFetching(false));
  }
}
