import React from 'react';
import logo from './logo.svg';
import './App.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Home from './components/Home/Home';
import HeaderAndFooter from './layout/HeaderAndFooter';
import Category from './components/Category/Category';
import Liked from './components/Liked/Liked';
import SearchPage from './components/SearchPage/SearchPage';
import NotFound from './components/UI/NotFound/NotFound';
import Product from './components/Product/Product';
import AdminLayout from './layout/AdminLayout';
import Admin from './components/Admin/Admin';
import AdminProducts from './components/Admin/AdminProducts/AdminProducts';
import AdminAddProduct from './components/Admin/AdminProducts/AdminAdd/AdminAdd';
import AdminCategories from './components/Admin/AdminCategories/AdminCategories';
import AddCategory from './components/Admin/AdminCategories/AddCategory/AddCategory';
import EditCategory from './components/Admin/AdminCategories/EditCategory/EditCategory';
import AdminEditProduct from './components/Admin/AdminProducts/AdminEdit/AdminEdit';
import Checkout from './components/Checkout/Checkout';
import Login from './components/Login/Login';
import ThanksForOrder from './components/Checkout/ThanksForOrder/ThanksForOrder';
import AdminOrders from './components/Admin/AdminOrders/AdminOrders';
import { useLocation } from 'react-router-dom';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route element={<HeaderAndFooter/>}>
          <Route path='/' element={<Home/>}/>
          <Route path='/category/:id' element={<Category/>}/>
          <Route path='/liked' element={<Liked/>}/>
          <Route path='/search/' element={<SearchPage/>}/>
          <Route path='/product/:id' element={<Product/>}/>
          <Route path='/checkout' element={<Checkout/>}/>
          <Route path='*' element={<NotFound/>}/>
        </Route>
        <Route element={<AdminLayout/>}>
          <Route path='/admin/' element={<Admin/>}/>
          <Route path='/admin/products/' element={<AdminProducts/>}/>
          <Route path='/admin/add-product' element={<AdminAddProduct/>}/>
          <Route path='/admin/edit-product/:id' element={<AdminEditProduct/>}/>
          <Route path='/admin/categories/' element={<AdminCategories/>}/>
          <Route path='/admin/add-category' element={<AddCategory/>}/>
          <Route path='/admin/edit-category/:id' element={<EditCategory/>}/>
          <Route path='/admin/orders' element={<AdminOrders/>}/>
        </Route>
        <Route path='/login' element={<Login/>}/>
        <Route path='/checkout/success' element={<ThanksForOrder/>}/>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
