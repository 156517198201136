import React, { useEffect, useState } from 'react';
import CategoryItem from '../UI/CategoryItem/CategoryItem';
import SkeletonLoader from '../UI/SkeletonLoader/SkeletonLoader';
import { ItemByCategory, ItemsByCategory } from '../../types/item.types';
import styles from './Category.module.css';
import { RootState } from '../../types/store.types';
import { getFilteredItems, getItemsByCategory } from '../../redux/reducers/item-reducer';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import { CategoryType } from '../../types/category.types';
import { getCategories } from '../../redux/reducers/category-reducer';
import { getLikedItems } from '../../redux/reducers/liked-reducer';
import { LikedItems } from '../../types/liked.types';
import Pagination from '../UI/Pagination/Pagination';
import Filter from './Filter/Filter';
import { useMediaQuery } from '@uidotdev/usehooks';
import { FaFilter } from "react-icons/fa";
import CategoryNotFound from '../UI/ItemsNotFound/ItemsNotFound';

interface CategoryProps {
  categoryItems: ItemsByCategory;
  isFetching: boolean;
  getItemsByCategory: (category: number, page: number) => void;
  caregories: CategoryType[];
  getCategories: () => void;
  likedItems: LikedItems;
  isLikedFetching: boolean;
  getLikedItems: () => void;
  getFilteredItems: (categoryId: number, min_price?:number,max_price?:number,color?:string,size?:string,sort_by?:string,order?:string, page?:number) => void;
}

interface SortConfigType{
  [key: string]: { display: string, sort_by: string, order: string }
}

const Category = (props: CategoryProps) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedSort, setSelectedSort] = useState(''); 
  const [filteredItems, setFilteredItems] = useState<{ priceRange: number[], selectedColors: string[], selectedSizes: string[] }>({priceRange: [0, 10000], selectedColors: [], selectedSizes: []});
  const [isFilterVisible, setIsFilterVisible] = useState(false); 
  const filterState = () => {
    setIsFilterVisible((prevState) => !prevState);
  };
  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };
  const sortConfig:SortConfigType = {
    popularity: { display: 'За популярністю', sort_by: 'average_rating', order: 'desc' },
    cheapest: { display: 'Спочатку дешевше', sort_by: 'price', order: 'asc' },
    expensive: { display: 'Спочатку дорожчі', sort_by: 'price', order: 'desc' },
    created_at: { display: 'Найновіші', sort_by: 'created_at', order: 'desc' },
  };
  const onFilterHandler = (priceRange: number[], selectedColors: string[], selectedSizes: string[]) => {
      let filterObj = {
        priceRange,
        selectedColors,
        selectedSizes
      }
      setFilteredItems(filterObj);
  }

  useEffect(() => {

    props.getFilteredItems(Number(id), filteredItems.priceRange[0], filteredItems.priceRange[1], filteredItems.selectedColors.join(), filteredItems.selectedSizes.join(), sortConfig[selectedSort]?.sort_by, sortConfig[selectedSort]?.order, currentPage);
  }, [filteredItems, currentPage, selectedSort]);

  const { id } = useParams<{ id: string }>();

  useEffect(() => {
    props.getItemsByCategory(Number(id), currentPage);
    props.getCategories();
    props.getLikedItems();
    window.scrollTo(0, 0);
  }, [id, currentPage]);
  useEffect(() => {
    setCurrentPage(1);
    setSelectedSort('');
  },[id]);

  const likedIds = props.likedItems.favorites.map((item) => item.product_id);

  // Use media queries to set the number of items per row based on screen size
  const isLargeScreen = useMediaQuery('only screen and (min-width: 1024px)');
  const isMediumScreen = useMediaQuery('only screen and (min-width: 768px) and (max-width: 1023px)');
  


  const itemsPerRow = isLargeScreen ? 3 : isMediumScreen ? 2 : 1;

  // Split the products array into rows of `itemsPerRow`
  const rowsOfItems = [];
  for (let i = 0; i < props.categoryItems.products.length; i += itemsPerRow) {
    rowsOfItems.push(props.categoryItems.products.slice(i, i + itemsPerRow));
  }

  // Handler for sort button click
  const handleSortChange = (sortOption: string) => {
    setSelectedSort(sortOption);
  };

  return (
    <main className={styles.main}>
      <h2 className={styles.title}>
        {props.isFetching ? '' : props.caregories.find((category) => category.category_id === Number(id))?.name}
      </h2>
      <div className={styles.filterContainer}>
        <button className={styles.filterButton} onClick={filterState}>
          <FaFilter></FaFilter>
        </button>
        <div className={isFilterVisible ? styles.showFilter : styles.hiddenFilter}><Filter onFilterHandler={onFilterHandler}/></div>
      </div>
      <div className={styles.mainContainer}>
        <div className={styles.rightContainer}>
          <div className={styles.sortContainer}>
            <p className={styles.sortTitle}>Сортування:</p>
            <div className={styles.sortButtons}>
              {Object.entries(sortConfig).map(([key, value]) => (
                <button
                  key={key}
                  className={styles.sortButton}
                  onClick={() => handleSortChange(key)}
                  style={{
                    backgroundColor: selectedSort === key ? '#58595b' : 'transparent',
                    color: selectedSort === key ? '#ffffff' : '#000000',
                  }}
                >
                  {value.display}
                </button>
              ))}
              </div>
          </div>
          <div className={styles.categoryContainer}>
            {props.isFetching ? (
              <SkeletonLoader length={10} />
            ) : rowsOfItems.length!==0 ? (
              rowsOfItems.map((row, rowIndex) => (
                <div key={rowIndex} className={styles.row}>
                  {row.map((item: ItemByCategory) => (
                    <CategoryItem
                      key={item.product_id}
                      item={item}
                      isLiked={likedIds.includes(item.product_id)}
                    />
                  ))}
                </div>
              ))
            ):<CategoryNotFound/>}
          </div>
        </div>
      </div>
      <Pagination
        totalPages={props.categoryItems.totalPages}
        currentPage={currentPage}
        onPageChange={handlePageChange}
      />
    </main>
  );
};

const mapStateToProps = (state: RootState) => {
  return {
    categoryItems: state.item.categoryItems,
    isFetching: state.item.isFetching,
    caregories: state.category.categories,
    likedItems: state.liked.likedItems,
    isLikedFetching: state.liked.isFetching,
  };
};

const mapDispatchToProps = {
  getItemsByCategory,
  getCategories,
  getLikedItems,
  getFilteredItems,
};

export default connect(mapStateToProps, mapDispatchToProps)(Category);
