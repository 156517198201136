import React, { useEffect, useState } from 'react';
import AsyncSelect from 'react-select/async';
import Select from 'react-select';
import styles from './Checkout.module.css';
import novaPoshtaLogo from '../../assets/images/Nova_Poshta_2022_logo.png';
import { connect } from 'react-redux';
import { RootState } from '../../types/store.types';
import { clearCart, getCart } from '../../redux/reducers/cart-reducer';
import { CartType } from '../../types/cart.types';
import { useNavigate } from 'react-router-dom';
import { FaCreditCard, FaWallet } from 'react-icons/fa6';
import GooglePayButton from '@google-pay/button-react';
import { IoClose } from 'react-icons/io5';
import AlertMessage from '../UI/AlertMessage/AlertMessage';
import { makeOrder } from '../../redux/reducers/order-reducer';
import { OrderType } from '../../types/order.types';

interface CityOption {
  value: string;
  label: string;
}

interface WarehouseOption {
  value: string;
  label: string;
}

interface CheckoutProps {
  cart: CartType[];
  isFetching: boolean;
  getCart: () => void;
  makeOrder: (order:OrderType)=>void
  clearCart: ()=>void;
}

type DeliveryType = 'branch' | 'postamat' | 'courier';

const Checkout = (props: CheckoutProps) => {
  const navigate = useNavigate();
  const [selectedCity, setSelectedCity] = useState<CityOption | null>(null);
  const [googlePayModal, setGooglePayModal] = useState(false);
  const [warehouses, setWarehouses] = useState<WarehouseOption[]>([]);
  const [selectedWarehouse, setSelectedWarehouse] = useState<WarehouseOption | null>(null);
  const [deliveryType, setDeliveryType] = useState<DeliveryType>('branch');
  const [paymentMethod, setPaymentMethod] = useState<'branch' | 'online'>('online');
  const [errors, setErrors] = useState<{ [key: string]: string }>({});
  const [onCancel, setOnCancel] = useState(false);
  
  const [formData, setFormData] = useState({
    fullName: '',
    phone: '',
    email: '',
    street: '',
    house: '',
    apartment: '',
  });

  useEffect(() => {
    props.getCart();
  }, []);

  useEffect(() => {
    if (props.cart.length === 0) {
      navigate('/');
    }
  }, [props.cart]);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
    setErrors((prev) => ({ ...prev, [name]: '' }));
  };

  const loadCityOptions = async (inputValue: string): Promise<CityOption[]> => {
    if (!inputValue) return [];
    try {
      const response = await fetch('https://api.novaposhta.ua/v2.0/json/', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          apiKey: 'c6239b720d4fda82b5e7e9bd76d72686',
          modelName: 'AddressGeneral',
          calledMethod: 'searchSettlements',
          methodProperties: { CityName: inputValue, Limit: '10', Page: '1' },
        }),
      });
      const data = await response.json();
      const citySuggestions = data.data[0]?.Addresses.map((city: any) => ({
        value: city.DeliveryCity,
        label: city.Present,
      }));
      return citySuggestions || [];
    } catch (error) {
      console.error('Error fetching city suggestions:', error);
      return [];
    }
  };

  const loadWarehouses = async (cityRef: string) => {
    try {
      const response = await fetch('https://api.novaposhta.ua/v2.0/json/', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          apiKey: 'c6239b720d4fda82b5e7e9bd76d72686',
          modelName: 'AddressGeneral',
          calledMethod: 'getWarehouses',
          methodProperties: { CityRef: cityRef, Limit: '50', Page: '1' },
        }),
      });
      const data = await response.json();
      const warehouseOptions = data.data.map((warehouse: any) => ({
        value: warehouse.Ref,
        label: warehouse.Description,
      }));
      setWarehouses(warehouseOptions);
    } catch (error) {
      console.error('Error fetching warehouse suggestions:', error);
    }
  };
  const getTotalPrice = (): string => {
    const total = props.cart.reduce((acc, item) => acc + Number(item.price) * item.quantity, 0);
    return paymentMethod === 'branch' ? '200.00' : total.toFixed(2);
  };

  const handlePaymentSuccess = (paymentData: any) => {
    console.log('Payment Successful:', paymentData);
    setGooglePayModal(false);
    let address = selectedWarehouse?.label === undefined ? formData.street : selectedWarehouse?.label
      if(address=== formData.street){
        if(formData.house === "" || formData.house===null){
          address = address + ", квартира "+formData.apartment
        }
        else{
          address = address + ", буд "+formData.house
        }
      }
      const order:OrderType ={
        guest_name: formData.fullName,
        guest_email: formData.email,
        guest_phone: formData.phone,
        city: selectedCity?.label||'',
        address: address || '',
        delivery_method: deliveryType ? 'Відділення нової пошти': "Кур'єр",
        products: props.cart.map((cart: CartType) => {
          return {
            product_id: cart.product_id,
            quantity: cart.quantity,
            color: cart.color || '',
            size: String(cart.size), 
            price: cart.price
          };
        }),
        total_price: props.cart.reduce((acc, item) => acc + Number(item.price) * item.quantity, 0),
        payment_method: paymentMethod === 'branch'? 'Передоплата 200 грн' : 'Повна оплата на сайті'
      }
      props.makeOrder(order);
    setTimeout(() => {
      props.clearCart();
      navigate('/checkout/success');     
    }, 300);
    
  };

  const handleCityChange = (selectedOption: CityOption | null) => {
    setSelectedCity(selectedOption);
    setSelectedWarehouse(null);
    if (selectedOption) {
      loadWarehouses(selectedOption.value);
    }
  };

  const handleDeliveryTypeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newDeliveryType = event.target.value as DeliveryType;
    setDeliveryType(newDeliveryType);
    setSelectedWarehouse(null);
    if (selectedCity) {
      loadWarehouses(selectedCity.value);
    }
  };

  const validateForm = () => {
    const newErrors: { [key: string]: string } = {};
    if (!formData.fullName) newErrors.fullName = 'Будь ласка, введіть ПІБ.';
    if (!formData.phone) newErrors.phone = 'Будь ласка, введіть номер телефону.';
    else if (!/^\+?\d{8,15}$/.test(formData.phone)) newErrors.phone = 'Невірний формат номера телефону.';
    if (!formData.email) newErrors.email = 'Будь ласка, введіть електронну адресу.';
    else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formData.email)) newErrors.email = 'Невірний формат електронної адреси.';
    if (!selectedCity) newErrors.city = 'Будь ласка, виберіть населений пункт.';
    if (deliveryType === 'branch' && !selectedWarehouse) {
      newErrors.warehouse = 'Будь ласка, виберіть відділення.';
    }
    if (deliveryType === 'courier' && !formData.street) {
      newErrors.street = 'Будь ласка, введіть вулицю.';
    }
    if (deliveryType === 'courier' && !(formData.house || formData.apartment)) {
      newErrors.house = 'Будь ласка, введіть будинок або квартиру.';
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = () => {
    if (validateForm()) {
      
      setGooglePayModal(true);
    }
  };

  const handleCancel = () => {
    setOnCancel(true);
    setGooglePayModal(false);
    setTimeout(() => {
      setOnCancel(false);
    }, 3000);
  }

  return (
    <main>
      <h1 className={styles.title}>Оформлення замовлення</h1>
      {onCancel && <AlertMessage message="Плату було скасовано" backgroundColor='#F88E86' />}
      {googlePayModal && (
        <div className={styles.darkTransparentBackground}>
        <div className={styles.googlePayModal}>
        <IoClose className={styles.closeIcon} onClick={() => setGooglePayModal(false)} />
        <GooglePayButton
              environment="TEST" // Change to 'PRODUCTION' for live payments
              paymentRequest={{
                apiVersion: 2,
                apiVersionMinor: 0,
                allowedPaymentMethods: [
                  {
                    type: 'CARD',
                    parameters: {
                      allowedAuthMethods: ['PAN_ONLY', 'CRYPTOGRAM_3DS'],
                      allowedCardNetworks: ['VISA', 'MASTERCARD'],
                    },
                    tokenizationSpecification: {
                      type: 'PAYMENT_GATEWAY',
                      parameters: {
                        gateway: 'liqpay', // Replace with your gateway
                        gatewayMerchantId: 'sandbox_i27593421628', // Replace with your Merchant ID
                      },
                    },
                  },
                ],
                merchantInfo: {
                  merchantId: 'YOUR_MERCHANT_ID', // Replace with your Google Pay Merchant ID
                  merchantName: 'Your Business Name',
                },
                transactionInfo: {
                  totalPriceStatus: 'FINAL',
                  totalPrice: getTotalPrice(),
                  currencyCode: 'UAH',
                  countryCode: 'UA',
                },
              }}
              onLoadPaymentData={handlePaymentSuccess}
              onCancel={handleCancel}
              buttonColor="black"
              buttonType="buy"
            />
        </div>
        </div>
      )}
      <div className={styles.checkoutContainer}>
        <div className={styles.form}>
          <div className={styles.userInfo}>
            <h2 className={styles.subtitle}>Контактні дані</h2>
            <div className={styles.rowContainer}>
              <div className={styles.row}>
                <label className={styles.label}>ПІБ</label>
                <input
                  className={styles.input}
                  type="text"
                  name="fullName"
                  value={formData.fullName}
                  onChange={handleInputChange}
                />
                {errors.fullName && <p className={styles.error}>{errors.fullName}</p>}
              </div>
              <div className={styles.row}>
                <label className={styles.label}>Телефон</label>
                <input
                  className={styles.input}
                  type="tel"
                  name="phone"
                  value={formData.phone}
                  onChange={handleInputChange}
                />
                {errors.phone && <p className={styles.error}>{errors.phone}</p>}
              </div>
            </div>
            <div className={styles.emailRow}>
              <label className={styles.label}>Е-Пошта</label>
              <input
                className={styles.input}
                type="text"
                name="email"
                value={formData.email}
                onChange={handleInputChange}
              />
              {errors.email && <p className={styles.error}>{errors.email}</p>}
            </div>
          </div>

          <div className={styles.deliveryInfo}>
            <h2 className={styles.subtitle}>Доставка</h2>
            <div className={styles.novaPoshtaContainer}>
              <img src={novaPoshtaLogo} alt="Nova Poshta" className={styles.novaPoshtaLogo} />
              <p className={styles.novaPoshtaDescription}>Доставка здійснюється виключно новою поштою</p>
            </div>

            {/* Radio buttons for delivery options */}
            <h2 className={styles.subtitle + ' ' + styles.typeTitle}>Спосіб доставки</h2>
            <div className={styles.deliveryTypeOptions}>
              <label className={styles.radioLabel}>
                <input
                  type="radio"
                  name="deliveryType"
                  value="branch"
                  checked={deliveryType === 'branch'}
                  onChange={handleDeliveryTypeChange}
                />
                На відділення або поштомат
              </label>

              <label className={styles.radioLabel}>
                <input
                  type="radio"
                  name="deliveryType"
                  value="courier"
                  checked={deliveryType === 'courier'}
                  onChange={handleDeliveryTypeChange}
                />
                Кур'єром
              </label>
            </div>

            {deliveryType !== 'courier' && (
              <div className={styles.deliverySelect}>
                <div className={styles.inputGroup}>
                  <label className={styles.label}>Населений пункт</label>
                  <AsyncSelect
                    className={styles.select}
                    placeholder="Виберіть населений пункт"
                    isClearable
                    loadOptions={loadCityOptions}
                    onChange={(option) => {
                      handleCityChange(option);
                      setErrors((prev) => ({ ...prev, city: '' }));
                    }}
                    value={selectedCity}
                  />
                  {errors.city && <p className={styles.error}>{errors.city}</p>}
                </div>

                {selectedCity && (
                  <div className={styles.inputGroup}>
                    <label className={styles.label}>Відділення</label>
                    <Select
                      className={styles.select}
                      placeholder="Виберіть відділення"
                      options={warehouses}
                      onChange={(option) => {
                        setSelectedWarehouse(option);
                        setErrors((prev) => ({ ...prev, warehouse: '' }));
                      }}
                      value={selectedWarehouse}
                    />
                    {errors.warehouse && <p className={styles.error}>{errors.warehouse}</p>}
                  </div>
                )}
              </div>
            )}

            {deliveryType === 'courier' && (
              <div className={styles.courierInfo}>
              <div className={styles.inputGroup}>
                  <label className={styles.label}>Населений пункт</label>
                  <AsyncSelect
                    className={styles.select}
                    placeholder="Виберіть населений пункт"
                    isClearable
                    loadOptions={loadCityOptions}
                    onChange={(option) => {
                      handleCityChange(option);
                      setErrors((prev) => ({ ...prev, city: '' }));
                    }}
                    value={selectedCity}
                  />
                  {errors.city && <p className={styles.error}>{errors.city}</p>}
                </div>
                <div className={styles.inputGroup}>
                  <label className={styles.label}>Вулиця</label>
                  <input
                    type="text"
                    className={styles.input}
                    name="street"
                    value={formData.street}
                    onChange={handleInputChange}
                  />
                  {errors.street && <p className={styles.error}>{errors.street}</p>}
                </div>

                <div className={styles.streetAndHouse}>
                  <div className={styles.row}>
                    <label className={styles.label}>Будинок</label>
                    <input
                      type="text"
                      className={styles.input}
                      name="house"
                      value={formData.house}
                      onChange={handleInputChange}
                      disabled={formData.apartment ? true:false}                      
                    />
                  </div>
                  <div className={styles.row}>
                    <label className={styles.label}>Квартира</label>
                    <input
                      type="text"
                      className={styles.input}
                      name="apartment"
                      value={formData.apartment}
                      onChange={handleInputChange}
                      disabled={formData.house ? true:false}
                    />
                  </div>
                  {errors.house && <p className={styles.error}>{errors.house}</p>}
                </div>
              </div>
            )}
          </div>
        </div>

        <div>
        <h2 className={styles.subtitle+' '+styles.cartTitle}>Ваш кошик</h2>
        <div className={styles.cartItems}>
          {
            props.isFetching ? <p>Loading...</p> : props.cart.map((item) => (
              <div key={`${item.product_id}-${item.color}-${item.size}`} className={styles.cartItem}>
                <img src={item.media[0].media_url} alt={item.name} className={styles.itemImage} />
                <div className={styles.itemInfo}>
                  <p className={styles.itemTitle}>{item.name}</p>
                  <p className={styles.itemPrice}>{item.price} грн</p>
                  <p className={styles.itemColor}>Колір: {item.color}</p>
                  <p className={styles.itemSize}>Розмір: {item.size}</p>
                  <p className={styles.itemQuantity}>Кількість: {item.quantity}</p>
                </div>
              </div>
            ))
          }

        </div>
                 {/* Vylepšená sekce výběru platby */}
                 <h2 className={styles.subtitle + ' ' + styles.typeTitle}>Спосіб оплати</h2>
          <div className={styles.paymentOptions}>
            <div
              className={`${styles.paymentOption} ${
                paymentMethod === 'branch' ? styles.selectedPayment : ''
              }`}
              onClick={() => setPaymentMethod('branch')}
            >
              <FaWallet className={styles.icon} />
              <div>
                <h3>Оплата на відділенні</h3>
                <p>Передоплата 200 грн обов'язкова.</p>
              </div>
            </div>

            <div
              className={`${styles.paymentOption} ${
                paymentMethod === 'online' ? styles.selectedPayment : ''
              }`}
              onClick={() => setPaymentMethod('online')}
            >
              <FaCreditCard className={styles.icon} />
              <div>
                <h3>Повна оплата онлайн</h3>
                <p>Оплатіть повну суму безпосередньо через вебсайт.</p>
              </div>
            </div>
          </div>

        <p className={styles.totalPrice}>Загальна вартість: {props.cart.reduce((acc, item) => acc + Number(item.price) * item.quantity, 0)} грн</p>
        <button className={styles.submitButton} onClick={handleSubmit}>
            Оформити замовлення
          </button>
        
        </div>
        
      </div>

    </main>
  )
}

let mapStateToProps = (state: RootState) => {
  return {
    cart: state.cart.cart,
    isFetching: state.cart.isFetching,
  }
}

let mapDispatchToProps = {
  getCart,
  makeOrder,
  clearCart
}

export default connect(mapStateToProps, mapDispatchToProps)(Checkout);
