import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { RootState } from '../../../../types/store.types';
import { OrderDetailsType } from '../../../../types/order.types';
import { getOrderDetails } from '../../../../redux/reducers/order-reducer';
import style from './OrderDetail.module.css';
import { orderApi } from '../../../../api/api';
import { FaPencil } from 'react-icons/fa6';
import AlertMessage from '../../../UI/AlertMessage/AlertMessage';

interface OrderDetailProps {
  order_id: number;
  singleOrder: OrderDetailsType | null;
  isFetching: boolean;
  getOrderDetails: (id: number) => void;
  onClose: () => void;
}

const OrderDetail = (props: OrderDetailProps) => {
  const { order_id } = props;
  const [isFetching, setIsFetching] = useState<boolean>(false);
  const [singleOrder, setSingleOrder] = useState<OrderDetailsType | null>(null);
  const [editedOrder, setEditedOrder] = useState<OrderDetailsType | null>(null);
  const [isEditing, setIsEditing] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [successModal, setSuccessModal] = useState<boolean>(false)
  const [errorModal, setErrorModal] = useState<boolean>(false)

  useEffect(() => {
    fetchOrder(order_id);
  }, [order_id]);

  const fetchOrder = async (id: number) => {
    try {
      setIsFetching(true);
      setError(null);
      const fetchedOrder = await orderApi.getOrderDetails(id);
      setSingleOrder(fetchedOrder);
      setEditedOrder(fetchedOrder);
    } catch (error) {
      console.error('Error fetching order details:', error);
      setError('Не вдалося завантажити деталі замовлення.');
    } finally {
      setIsFetching(false);
    }
  };

  const handleInputChange = (
    field: string,
    value: string | number,
    isNested: boolean = false
  ) => {
    if (!editedOrder) return;
    if (isNested) {
      setEditedOrder((prev) => ({
        ...prev!,
        guest: { ...prev!.guest, [field]: value },
      }));
    } else {
      setEditedOrder((prev) => ({ ...prev!, [field]: value }));
    }
  };
  const handleEditSubmit = async () => {
    try {
      setIsFetching(true);
      setErrorModal(false);
      setSuccessModal(false);

      if (!editedOrder) {
        throw new Error("Neplatná data pro aktualizaci.");
      }
      let order = {
        order_id: editedOrder.order_id,
        total_price: editedOrder.total,
        status: editedOrder.status,
        created_at: editedOrder.created_at,
        address: editedOrder.guest.address,
        city: editedOrder.guest.city,
        payment_method: editedOrder.guest.payment_method,
        delivery_method: editedOrder.guest.delivery_method,
        guest_name: editedOrder.guest.name, 
        guest_email:editedOrder.guest.email, 
        guest_phone:editedOrder.guest.phone,
        products:editedOrder.products
      }
      console.log(order)
      await orderApi.updateOrder(order.order_id, order);

      setSuccessModal(true); // Zobrazit úspěšnou zprávu
      setTimeout(() => setSuccessModal(false), 3000); // Skrytí zprávy po 3s
      setIsEditing(false); // Zavřít editační režim
      fetchOrder(order_id); // Znovu načíst aktualizované detaily objednávky
    } catch (error) {
      console.error("Error updating order:", error);
      setErrorModal(true); // Zobrazit chybovou zprávu
      setTimeout(() => setErrorModal(false), 3000); // Skrytí zprávy po 3s
    } finally {
      setIsFetching(false);
    }
  };

  if (isFetching) {
    return <div className={style.loader}>Завантаження даних...</div>;
  }

  if (error) {
    return <div className={style.error}>{error}</div>;
  }

  if (!singleOrder || !editedOrder) {
    return <div className={style.error}>Замовлення не знайдено.</div>;
  }

  return (
    <div className={style.modalOverlay}>
      {errorModal ? <AlertMessage message='Виникла помилка' backgroundColor='#f44336' ></AlertMessage> : null}
      {successModal ? <AlertMessage message='Замовлення успішно змінено'></AlertMessage> : null}
      <div className={style.modalContent}>
        <button className={style.closeButton} onClick={props.onClose}>
          &times;
        </button>
        <FaPencil
          className={style.edit}
          onClick={() => setIsEditing(!isEditing)}
          title="Редагувати"
        />
        <h2 className={style.title}>
          Деталі замовлення #{singleOrder.order_id}
        </h2>

        <div className={style.details}>
          <p>
            <strong>Статус:</strong> {singleOrder.status}
          </p>
          <p>
            <strong>Дата створення:</strong>{' '}
            {new Date(singleOrder.created_at).toLocaleString('uk-UA')}
          </p>
          {isEditing ? (
            <>
              <p>
                <strong>Ім'я:</strong>
                <input
                  type="text"
                  value={editedOrder.guest.name}
                  onChange={(e) =>
                    handleInputChange('name', e.target.value, true)
                  }
                />
              </p>
              <p>
                <strong>Email:</strong>
                <input
                  type="email"
                  value={editedOrder.guest.email}
                  onChange={(e) =>
                    handleInputChange('email', e.target.value, true)
                  }
                />
              </p>
              <p>
                <strong>Телефон:</strong>
                <input
                  type="tel"
                  value={editedOrder.guest.phone}
                  onChange={(e) =>
                    handleInputChange('phone', e.target.value, true)
                  }
                />
              </p>
              <p>
                <strong>Адреса:</strong>
                <input
                  type="text"
                  value={editedOrder.guest.address}
                  onChange={(e) =>
                    handleInputChange('address', e.target.value, true)
                  }
                />
              </p>
            </>
          ) : (
            <>
              <p>
                <strong>Ім'я:</strong> {singleOrder.guest.name}
              </p>
              <p>
                <strong>Email:</strong> {singleOrder.guest.email}
              </p>
              <p>
                <strong>Телефон:</strong> {singleOrder.guest.phone}
              </p>
              <p>
                <strong>Адреса:</strong> {singleOrder.guest.address},{' '}
                {singleOrder.guest.city}
              </p>
            </>
          )}
          <p>
            <strong>Метод оплати:</strong> {singleOrder.guest.payment_method}
          </p>
          <p>
            <strong>Метод доставки:</strong> {singleOrder.guest.delivery_method}
          </p>
        </div>

        <h3>Продукти</h3>
        <table className={style.productTable}>
          <thead>
            <tr>
              <th>ID</th>
              <th>Назва продукту</th>
              <th>Кількість</th>
              <th>Ціна</th>
            </tr>
          </thead>
          <tbody>
            {editedOrder.products.map((product, index) => (
              <tr key={index}>
                <td>{product.product_id}</td>
                <td>{product.name}</td>
                <td>
                  {isEditing ? (
                    <input
                      type="number"
                      value={product.quantity}
                      onChange={(e) =>
                        setEditedOrder((prev) => {
                          const updatedProducts = [...prev!.products];
                          updatedProducts[index].quantity = parseInt(
                            e.target.value
                          );
                          return { ...prev!, products: updatedProducts };
                        })
                      }
                    />
                  ) : (
                    product.quantity
                  )}
                </td>
                <td>{product.price} грн</td>
              </tr>
            ))}
          </tbody>
        </table>

        {isEditing && (
          <div className={style.containerBtn}>
            <button
              className={style.saveButton}
              onClick={() => handleEditSubmit()}
            >
              Зберегти
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state: RootState) => ({
  singleOrder: state.order.singleOrder,
  isFetching: state.order.isFetching,
});

const mapDispatchToProps = {
  getOrderDetails,
};

export default connect(mapStateToProps, mapDispatchToProps)(OrderDetail);
