import React from 'react'
import { connect } from 'react-redux'
import { RootState } from '../../../types/store.types'
import { deleteItem, getItems } from '../../../redux/reducers/item-reducer'
import { useState, useEffect } from 'react'
import { ItemType } from '../../../types/item.types'
import style from './AdminProducts.module.css'
import ConfirmDeleteModal from '../../UI/ConfirmDeleteModal/ConfirmDeleteModal'
import AlertMessage from '../../UI/AlertMessage/AlertMessage'
import { useNavigate } from 'react-router-dom'

interface AdminProductsProps {
    items: ItemType[];
    isFetching: boolean;
    getItems: () => void;
    deleteItem: (id: number) => void;
}

const AdminProducts = (props: AdminProductsProps) => {
    const [isModalOpen, setIsModalOpen] = useState(false)
    const [isAlertVisible, setIsAlertVisible] = useState(false)
    const [deleteMessage, setDeleteMessage] = useState('')
    const [selectedProduct, setSelectedProduct] = useState<{ id: number, name: string } | null>(null);

    const navigate = useNavigate()

    const handleDelete = (product: { id: number, name: string }) => {
        setSelectedProduct(product)
        setIsModalOpen(true)
    }

    const cancelDelete = () => {
        setIsModalOpen(false)
    }

    useEffect(() => {
        props.getItems()
    }, [])

    const handleAddProduct = () => {
        navigate('/admin/add-product')
    }
    const handleEditProduct = (id: number) => {
        navigate(`/admin/edit-product/${id}`)
    }

    const confirmDelete = async () => {
        if (selectedProduct) {
          try {
            await props.deleteItem(selectedProduct.id); // Delete the selected product
            setIsModalOpen(false); // Close the modal
    
            // Show success alert
            setDeleteMessage(`Продукт "${selectedProduct.name}" був успішно видалений.`);
            setIsAlertVisible(true);
    
            // Refresh the product list after deletion
            props.getItems();
    
            // Hide the alert after 3 seconds
            setTimeout(() => setIsAlertVisible(false), 3000);
          } catch (error) {
            console.error('Error deleting product:', error);
          }
        }
      };

    return (
        <div className={style.container}>
            <div className={style.header}>
                <h2>Управління продуктами</h2>
                <button className={style.addProductBtn} onClick={handleAddProduct}>Додати продукт</button>
            </div>
            {isAlertVisible && <AlertMessage message={deleteMessage} />} {/* Show alert if visible */}
            {props.isFetching ? (
                <p>Завантаження...</p>
            ) : (
                <table className={style.productTable}>
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>Назва</th>
                            <th>Ціна</th>
                            <th>Опис</th>
                            <th>Категорія</th>
                            <th>Дії</th>
                        </tr>
                    </thead>
                    <tbody>
                        {props.items.map((item, index) => (
                            <tr key={item.product_id}>
                                <td>{item.product_id}</td>
                                <td>{item.name}</td>
                                <td>{item.price} грн</td>
                                <td>
                                    {// display /n as a new line
                                    item.description.split('\n').map((line, index) => (
                                        <span key={index}>
                                            {line}
                                            <br />
                                        </span>
                                        
                                    ))}
                                </td>
                                <td>{item.category_id}</td>
                                <td>
                                    <button className={style.actionBtn} onClick={()=>handleEditProduct(item.product_id)}>
                                        ✏️
                                    </button>
                                    <button className={style.actionBtn} onClick={() => handleDelete({ id: item.product_id, name: item.name })}>
                                        🗑️
                                    </button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            )}

            {/* Render the modal if isModalOpen is true */}

            {isModalOpen && selectedProduct && (
                <ConfirmDeleteModal
                    name={selectedProduct.name}
                    onConfirm={confirmDelete}
                    onCancel={cancelDelete}
                />
            )}

        </div>
    );
};

let mapStateToProps = (state: RootState) => {
    return {
        items: state.item.items,
        isFetching: state.item.isFetching
    }
}

let mapDispatchToProps = {
    getItems: getItems,
    deleteItem: deleteItem
}


export default connect(mapStateToProps, mapDispatchToProps)(AdminProducts)
