import React from 'react'
import styles from './Item.module.css'
import { ItemCard, ItemType } from '../../../types/item.types'
import aboutUs2 from '../../../assets/images/aboutUs2.jpg'
import { NavLink } from 'react-router-dom'
import { SimilarProduct } from '../../../types/similar.types'

const Item = (props: { item: ItemType | SimilarProduct }) => {
    return (
        <article className={styles.item}>
            <NavLink to={`/product/${props.item.product_id}`} className={styles.link}>
                <div className={styles.imageWrapper}>
                    <img src={props.item.media[0]?.media_url || aboutUs2} alt="Новинка 1" className={styles.image} />
                </div>
            </NavLink>
            {
                props.item.isNew ? (
                    <div className={styles.new}>Новинка</div>
                ) : null
            }
            <div className={styles.container}>
                <p className={styles.title}>{props.item.name}</p>
                <p className={styles.price}>{props.item.price}</p>
            </div>
        </article>

    )
}

export default Item
