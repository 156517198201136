import React, { useState, useEffect } from 'react';
import styles from './AdminEdit.module.css';
import { connect } from 'react-redux';
import { RootState } from '../../../../types/store.types';
import { getCategories } from '../../../../redux/reducers/category-reducer';
import { CategoryType } from '../../../../types/category.types';
import { useDropzone } from 'react-dropzone';
import ColorSizeForm from './ColorSizeForm/ColorSizeForm';
import { getItem, updateProduct } from '../../../../redux/reducers/item-reducer';
import { useNavigate } from 'react-router-dom';
import AlertMessage from '../../../UI/AlertMessage/AlertMessage';
import { ProductType } from '../../../../types/product.types';
import { useParams } from 'react-router-dom';

interface AdminEditProductProps {
  categories: CategoryType[];
  isFetching: boolean;
  getCategories: () => void;
  getItem: (id: number) => void;
  singleItem: ProductType;
  isSingleItemFetching: boolean;
  updateProduct: (id: number, product: any) => void;
}
interface mediaType {
  media_url: string;
  media_type: string;
}

const AdminEditProduct = (props: AdminEditProductProps) => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [isAddColor, setIsAddColor] = useState(false);
  const [selectedColor, setSelectedColor] = useState(null);
  const [color, setColor] = useState('#FFDE58');
  const [sizes, setSizes] = useState<{ size: string; stock: string }[]>([{ size: '', stock: '' }]);
  const [otherMedia, setOtherMedia] = useState<File[]>([]);
  const [colorSizeForms, setColorSizeForms] = useState<{ color: string; sizes: { size: string; stock: string }[]; image: File[] | null, image_url: any }[]>([]);
  const [formData, setFormData] = useState({
    name: '',
    description: '',
    price: '',
    category: '4',
  });
  const [errors, setErrors] = useState<any>({});
  const [isAlertVisible, setIsAlertVisible] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [imageToDelete, setImageToDelete] = useState<any>([]);
  const [errorModal, setErrorModal] = useState(false);
  const [otherExistingMedia, setOtherExistingMedia] = useState<mediaType[]>([]);


  // Add a new color-size form
  const handleAddColorForm = () => {
    setColorSizeForms([...colorSizeForms, { color: '#FFDE58', sizes: [{ size: '', stock: '' }], image: null, image_url: [] }]);
  };
  const handleImageToDelete = (url: string) => {
    setImageToDelete((prev: any) => [...prev, url]);
  }
  const handleNameChange = (name: string) => {
    setFormData({ ...formData, name });
    setErrors({ ...errors, name: '' });
  }
  const handleDescriptionChange = (description: string) => {
    setFormData({ ...formData, description });
    setErrors({ ...errors, description: '' });
  }
  const handlePriceChange = (price: string) => {
    setFormData({ ...formData, price });
    setErrors({ ...errors, price: '' });
  }
  const handleCategoryChange = (category: string) => {
    setFormData({ ...formData, category });
    setErrors({ ...errors, category: '' });
  }


  const handleRemoveColorForm = () => {
    if (colorSizeForms.length === 0) return;
    const updatedForms = [...colorSizeForms];
    updatedForms.pop();
    let imageToDelete = colorSizeForms[colorSizeForms.length - 1].image_url[0];
    imageToDelete?.map((image: any) => setImageToDelete((prev: any) => [...prev, image.media_url]));
    setColorSizeForms(updatedForms);
  };

  const handleDrop = (acceptedFiles: File[]) => {
    setOtherMedia((prevFiles) => [...prevFiles, ...acceptedFiles]);
  };

  const handleColorSizeFormChange = (index: number, updatedForm: any) => {
    const updatedForms = [...colorSizeForms];
    updatedForms[index] = updatedForm;
    setColorSizeForms(updatedForms);
  };

  useEffect(() => {
    props.getCategories();
    props.getItem(Number(id));
  }, []);

  useEffect(() => {
    props.getCategories();
    props.getItem(Number(id));
  }, [id]);

  useEffect(() => {
    if (!props.isSingleItemFetching && props.singleItem) {
      const { name, description, price, category_id, inventory, media
      } = props.singleItem;
      console.log(name, description, price, category_id, inventory, media);
      setFormData({ name, description, price, category: category_id.toString() });

      //i have media as object with id_color and i need to extract it to array of objects with color and url

      let mediaArray = Object.keys(media).map((key) => {
        return { color: key, url: media[key] };
      })
      const newColorSizeForms = inventory.map((item) => ({
        color: item.color,
        sizes: item.sizes.map((size) => ({ size: size.size, stock: size.stock.toString() })),
        image: null,
        image_url: mediaArray.filter((media) => media.color.includes(item.color)).map((media) => media.url)
      }));
      newColorSizeForms.forEach((form, index) => {
        console.log(form.image_url[0]);
      });
      setColorSizeForms(newColorSizeForms);
      let otherMedia = Object.keys(media).map((key) => {
        
        let keySplit = key.split('_')
        if (keySplit[1] === '' || keySplit[1] === 'null' || keySplit[1] === 'undefined' || keySplit[1] === '0' || keySplit[1] === null) {
          return media[key];
        }
      })
      otherMedia = otherMedia.filter((media) => media !== undefined);
      //from array of arrays to single array
      otherMedia = [].concat.apply([], otherMedia);
      console.log(otherMedia);
      setOtherExistingMedia(otherMedia);
    }
  }, [props.singleItem, props.isSingleItemFetching]);



  const validateForm = () => {
    const newErrors: any = {};

    if (!formData.name) newErrors.name = 'Назва продукту є обов\'язковою';
    if (!formData.description) newErrors.description = 'Опис продукту є обов\'язковим';
    if (!formData.price || isNaN(parseFloat(formData.price))) newErrors.price = 'Ціна продукту повинна бути числом';
    if (!formData.category) newErrors.category = 'Категорія продукту є обов\'язковою';

    // Validate color-size forms
    colorSizeForms.forEach((form, index) => {
      form.sizes.forEach((sizeData, sizeIndex) => {
        if (!sizeData.size || !sizeData.stock || isNaN(parseInt(sizeData.stock))) newErrors[`size-stock-${index}`] = 'Розмір та кількість є обов\'язковими';

      });
      let imagesUrlCount = form.image_url[0]?.filter((image: any) => !imageToDelete.includes(image.media_url)).length;
      console.log(imagesUrlCount);
      if (form.image == null && imagesUrlCount === 0) {
        newErrors[`image-${index}`] = 'Зображення є обов\'язковим';
      }

    });

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async () => {
    // console.log(formData,"Colors: "+JSON.stringify(colorSizeForms),"Medias: "+otherMedia);
    if (validateForm()) {
      // console.log('Form submitted with data:', { ...formData, colorSizeForms, otherMedia });
      const productData = new FormData();
      productData.append('name', formData.name);
      productData.append('description', formData.description);
      productData.append('price', formData.price);
      productData.append('category_id', formData.category);
      productData.append('inventory',
        JSON.stringify(colorSizeForms.map((form) => ({
          color: form.color,
          color_hex: '',
          sizes: [
            ...form.sizes.map((sizeData) => ({
              size: sizeData.size,
              stock: Number(sizeData.stock)
            }))
          ]
        })))
      );
      colorSizeForms.forEach((form) => {
        if (form.image) {
          form.image.forEach((file) => {
            productData.append('image', file);
            productData.append('image_color', form.color);
          });
        }

      });

      otherMedia.forEach((file) => {
        if (file.type.startsWith('image')) {
          productData.append('image', file);
          productData.append('image_color', '');
        }
        else {
          productData.append('video', file);
        }
      });

      productData.append('media_to_delete', JSON.stringify(imageToDelete));

      productData.forEach((value, key) => {
        console.log(key, value);
      });


      try {
        await props.updateProduct(Number(id), productData);
        setAlertMessage(`Продукт "${formData.name}" був успішно оновлений.`);
        setIsAlertVisible(true);

        setTimeout(() => {
          navigate('/admin/products');
        }, 3000);
      }
      catch (error) {
        console.error('Failed to add product:', error);
        setErrorModal(true);

        setTimeout(() => {
          setErrorModal(false);
        }, 3000);
      }

    }
  };

  return (
    <div className={styles.addProductContainer}>
      {isAlertVisible && <AlertMessage message={alertMessage} />}
      {errorModal && <AlertMessage message="Не вдалося оновити продукт" backgroundColor='#f44336' />}
      <h2 className={styles.addProductTitle}>Змінити продукт</h2>
      <div className={styles.addProductForm}>
        {/* Product Name */}
        <div className={styles.addProductFormGroup}>
          <label htmlFor="name" className={styles.addProductLabel}>Назва продукту</label>
          <input
            type="text"
            id="name"
            name="name"
            className={`${styles.addProductInput}`}
            value={formData.name}
            onChange={(e) => handleNameChange(e.target.value)}
          />
          {errors.name && <div className={styles.errorText}>{errors.name}</div>}
        </div>

        {/* Product Description */}
        <div className={styles.addProductFormGroup}>
          <label htmlFor="description" className={styles.addProductLabel}>Опис продукту</label>
          <textarea
            id="description"
            name="description"
            className={`${styles.addProductInput} ${styles.addProductTextarea}`}
            rows={15}
            style={{ resize: 'none' }}
            value={formData.description}
            onChange={(e) => handleDescriptionChange(e.target.value)}
          />
          {errors.description && <div className={styles.errorText}>{errors.description}</div>}
        </div>

        {/* Product Price */}
        <div className={styles.addProductFormGroup}>
          <label htmlFor="price" className={styles.addProductLabel}>Ціна продукту</label>
          <input
            type="text"
            id="price"
            name="price"
            className={`${styles.addProductInput}`}
            value={formData.price}
            onChange={(e) => handlePriceChange(e.target.value)}
          />
          {errors.price && <div className={styles.errorText}>{errors.price}</div>}
        </div>

        {/* Product Category */}
        <div className={styles.addProductFormGroup}>
          <label htmlFor="category" className={styles.addProductLabel}>Категорія продукту</label>
          <select
            id="category"
            name="category"
            className={`${styles.addProductInput}`}
            value={formData.category}
            onChange={(e) => handleCategoryChange(e.target.value)}
          >
            {props.isFetching ? (
              <option>Завантаження...</option>
            ) : (
              props.categories.map((category) => (
                <option key={category.category_id} value={category.category_id}>{category.name}</option>
              ))
            )}
          </select>
          {errors.category && <div className={styles.errorText}>{errors.category}</div>}
        </div>

        {/* Color and Sizes */}
        <h2 className={styles.colorChoose}>Колір та величина</h2>
        <div className={styles.multipleContainerColor}>
          {colorSizeForms.map((form, index) => (
            <ColorSizeForm
              key={index}
              index={index}
              formData={form}
              onChange={(updatedForm: any) => handleColorSizeFormChange(index, updatedForm)}
              errors={errors}
              handleImageDelete={handleImageToDelete}
            />
          ))}
          <div className={styles.addRemoveColorButtons}>
            <button onClick={handleAddColorForm} className={styles.addColorBtn}>Додати колір</button>
            <button onClick={() => handleRemoveColorForm()} className={styles.addColorBtn}>Видалити колір</button>
          </div>
        </div>

        {/* Other Images */}
        <h2 className={styles.addImageProductTitle}>Розмірна сітка</h2>
        <div className={styles.addImageFormGroup}>
          <Dropzone onDrop={handleDrop} files={otherMedia} />
          <div className={styles.otherMediaContainer}>
            {otherMedia.map((file, index) => (
              <div key={index} className={styles.otherMediaItem}>
                {file.type.startsWith('image') ? (
                  <img src={URL.createObjectURL(file)} alt="product" className={styles.otherMediaImage} />
                ) : (
                  <video src={URL.createObjectURL(file)} controls className={styles.otherMediaVideo} />
                )}
                <button
                  className={styles.removeMediaButton}
                  onClick={() => setOtherMedia((prevFiles) => prevFiles.filter((f) => f !== file))}
                >
                  Видалити
                </button>
              </div>
            ))}
            {otherExistingMedia?.map((media, index) => (

              <div key={index} className={styles.otherMediaItem}>
                {
                  imageToDelete.includes(media.media_url) ? null : (

                    <>
                      {
                        media.media_type === 'image' ? (
                          <>
                            <img src={media.media_url} alt="product" className={styles.otherMediaImage} />
                            <button
                              className={styles.removeMediaButton}
                              onClick={() => handleImageToDelete(media.media_url)}
                            >
                              Видалити
                            </button>
                          </>


                        ) : media.media_type==="video"?(
                          
                          <>
              
                            <video src={media.media_url} controls className={styles.otherMediaVideo} />
                            <button
                              className={styles.removeMediaButton}
                              onClick={() => handleImageToDelete(media.media_url)}
                            >
                              Видалити
                            </button>
                          </>
                        ):null

                      }
                    </>
                  )
                }
              </div>
            ))}

          </div>
        </div>

        {/* Submit */}
        <button onClick={handleSubmit} className={styles.addProductSubmitBtn}>
          Змінити продукт
        </button>
      </div>
    </div>
  );
};

const Dropzone: React.FC<{ onDrop: (acceptedFiles: File[]) => void; files: File[] }> = ({ onDrop, files }) => {
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    multiple: true,
    accept: {
      'image/*': ['.png', '.jpg', '.jpeg'],
      'video/*': ['.mp4', '.avi'],
    },
  });

  return (
    <div {...getRootProps({ className: styles.dropzone })}>
      <input {...getInputProps()} />
      {isDragActive ? (
        <p>Перетягніть файл сюди ...</p>
      ) : files.length > 0 ? (
        <div>
          {files.map((f) => (
            <p key={f.name}>{f.name}</p>
          ))}
        </div>
      ) : (
        <p>Натисність сюди або перетягніть файл</p>
      )}
    </div>
  );
};

const mapStateToProps = (state: RootState) => ({
  categories: state.category.categories,
  isFetching: state.category.isFetching,
  singleItem: state.item.singleItem,
  isSingleItemFetching: state.item.isFetching,
});

const mapDispatchToProps = {
  getCategories,
  getItem,
  updateProduct,
};
export default connect(mapStateToProps, mapDispatchToProps)(AdminEditProduct);
