import React, { useState, useEffect } from 'react';
import style from './EditCategory.module.css'; // Make sure to create or adjust a CSS module for styling
import { connect } from 'react-redux';
import { getCategories, updateCategory } from '../../../../redux/reducers/category-reducer';
import { useParams, useNavigate } from 'react-router-dom';
import AlertMessage from '../../../UI/AlertMessage/AlertMessage'; // Optional: Reusing the alert component
import { RootState } from '../../../../types/store.types';
import { CategoryType } from '../../../../types/category.types';

interface AdminEditCategoryProps {
    categories: CategoryType[];
    getCategories: () => void;
    updateCategory: (id: number, name: string) => Promise<void>;
}

const EditCategory = (props: AdminEditCategoryProps) => {
    const { id } = useParams<{ id: string }>(); // Get category ID from the URL
    const navigate = useNavigate(); // For navigation after updating the category
    const [categoryName, setCategoryName] = useState('');
    const [error, setError] = useState('');
    const [isAlertVisible, setIsAlertVisible] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');

    useEffect(() => {
        props.getCategories(); // Fetch categories when component mounts
    }, []);

    useEffect(() => {
        if (props.categories && props.categories.length > 0) {
            const selectedCategory = props.categories.find(cat => cat.category_id === Number(id));
            if (selectedCategory) {
                setCategoryName(selectedCategory.name); // Set category name if found
            }
        }
    }, [props.categories, id]);

    const validateInputs = () => {
        if (!categoryName) {
            setError('Назва категорії є обов’язковою.');
            return false;
        }
        setError(''); // Clear any existing error
        return true;
    };

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setCategoryName(e.target.value);
    };

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (!validateInputs()) return;

        try {
            await props.updateCategory(Number(id), categoryName);
            setAlertMessage('Категорію успішно оновлено!');
            setIsAlertVisible(true);
            setTimeout(() => {
                setIsAlertVisible(false);
                navigate('/admin/categories'); // Navigate back to the categories list
            }, 3000);
        } catch (error) {
            console.error('Failed to update category:', error);
            setAlertMessage('Помилка оновлення категорії.');
            setIsAlertVisible(true);
            setTimeout(() => setIsAlertVisible(false), 3000);
        }
    };

    return (
        <div className={style.editCategoryContainer}>
            <h2 className={style.editCategoryTitle}>Редагувати категорію</h2>
            {isAlertVisible && <AlertMessage message={alertMessage} />} {/* Alert for success/error */}

            <form onSubmit={handleSubmit} className={style.editCategoryForm}>
                <div className={style.editCategoryFormGroup}>
                    <label htmlFor="name" className={style.editCategoryLabel}>Назва категорії</label>
                    <input
                        type="text"
                        id="name"
                        name="name"
                        value={categoryName}
                        onChange={handleInputChange}
                        className={`${style.editCategoryInput} ${error ? style.inputError : ''}`}
                    />
                    {error && <div className={style.errorMessage}>{error}</div>}
                </div>

                <button type="submit" className={style.editCategoryButton}>Зберегти</button>
            </form>
        </div>
    );
};

// Map state and dispatch to props
let mapStateToProps = (state: RootState) => {
    return {
       categories: state.category.categories,
       isFetching: state.category.isFetching,
    };
};

let mapDispatchToProps = {
    updateCategory: updateCategory,
    getCategories: getCategories,
};

export default connect(mapStateToProps, mapDispatchToProps)(EditCategory);
