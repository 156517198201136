import React, { useState } from 'react';
import style from './AddCategory.module.css'; // Make sure to create or modify a CSS module
import { connect } from 'react-redux';
import { addCategory } from '../../../../redux/reducers/category-reducer';
import { RootState } from '../../../../types/store.types';
import AlertMessage from '../../../UI/AlertMessage/AlertMessage' // Optional: Reusing the alert component
import { useNavigate } from 'react-router-dom';

interface AdminAddCategoryProps {
    addCategory: (name: string) => Promise<void>;
}

const AddCategory = (props: AdminAddCategoryProps) => {
    const navigate = useNavigate();
    const [categoryName, setCategoryName] = useState('');
    const [error, setError] = useState('');
    const [isAlertVisible, setIsAlertVisible] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');

    const validateInputs = () => {
        if (!categoryName) {
            setError('Назва категорії є обов’язковою.');
            return false;
        }
        setError(''); // Clear any existing error
        return true;
    };

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setCategoryName(e.target.value);
    };

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        if (!validateInputs()) return;

        try {
            await props.addCategory(categoryName);
            setAlertMessage('Категорію успішно додано!');
            setIsAlertVisible(true);
            setTimeout(() => {
                navigate('/admin/categories'); // Redirect after 3 seconds
            }, 3000);
        } catch (error) {
            console.error('Failed to add category:', error);
            setAlertMessage('Помилка додавання категорії.');
            setIsAlertVisible(true);
            setTimeout(() => {
                navigate('/admin/categories'); // Redirect after 3 seconds
              }, 3000);
        }
    };

    return (
        <div className={style.addCategoryContainer}>
            <h2 className={style.addCategoryTitle}>Додати категорію</h2>
            {isAlertVisible && <AlertMessage message={alertMessage} />} {/* Optional: Alert for success/error */}

            <form onSubmit={handleSubmit} className={style.addCategoryForm}>
                <div className={style.addCategoryFormGroup}>
                    <label htmlFor="name" className={style.addCategoryLabel}>Назва категорії</label>
                    <input
                        type="text"
                        id="name"
                        name="name"
                        value={categoryName}
                        onChange={handleInputChange}
                        className={`${style.addCategoryInput} ${error ? style.inputError : ''}`} // Apply error style if necessary
                    />
                    {error && <div className={style.errorMessage}>{error}</div>}
                </div>

                <button type="submit" className={style.addCategoryButton}>Додати</button>
            </form>
        </div>
    );
};

// Map state and dispatch to props (if necessary)
let mapStateToProps = (state: RootState) => {
    return {};
};

let mapDispatchToProps = {
    addCategory,
};

export default connect(mapStateToProps, mapDispatchToProps)(AddCategory);
