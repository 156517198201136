import { Dispatch } from 'redux';
import { searchApi } from '../../api/api';
import { SearchType } from '../../types/search.types';

// Action types
const SET_SEARCH_ITEMS = 'SET_SEARCH_ITEMS';
const SET_SEARCH_IS_FETCHING = 'SET_SEARCH_IS_FETCHING';

// Initial state
const initialState = {
  searchResults: {
    page: 0,
    totalPages: 0,
    totalProducts: 0,
    products: [] as SearchType[]
  },
  isFetching: false
};

// Reducer
export const searchReducer = (state = initialState, action: { type: string; payload: any }) => {
  switch (action.type) {
    case SET_SEARCH_ITEMS:
      return { ...state, searchResults: action.payload };
    case SET_SEARCH_IS_FETCHING:
      return { ...state, isFetching: action.payload };
    default:
      return state;
  }
};

// Action creators
export const setSearchItems = (items: typeof initialState.searchResults) => ({
  type: SET_SEARCH_ITEMS,
  payload: items
});

export const setSearchIsFetching = (isFetching: boolean) => ({
  type: SET_SEARCH_IS_FETCHING,
  payload: isFetching
});

// Thunk for asynchronous action
export const searchItems = (searchQuery: string, page: number) => async (dispatch: Dispatch) => {
  dispatch(setSearchIsFetching(true));
  try {
    const response = await searchApi.search(searchQuery, page);

    dispatch(setSearchItems(response));
  } finally {
    dispatch(setSearchIsFetching(false));
  }
};


