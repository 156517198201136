import {legacy_createStore as createStore,combineReducers, applyMiddleware  } from "redux";
import {thunk as thunkMiddleware} from 'redux-thunk';
import {itemReducer} from './reducers/item-reducer';
import {categoryReducer} from './reducers/category-reducer';
import { likedReducer } from "./reducers/liked-reducer";
import { searchReducer } from "./reducers/search-reducer";
import { cartReducer } from "./reducers/cart-reducer";
import { orderReducer } from "./reducers/order-reducer";

const reducers = combineReducers({
    item: itemReducer,
    category: categoryReducer,
    liked: likedReducer,
    search: searchReducer,
    cart: cartReducer,
    order:orderReducer
    
});  
const store = createStore(reducers,{}, applyMiddleware(thunkMiddleware));

export default store;