// import React from 'react'
// import { connect } from 'react-redux'
// import { RootState } from '../../../types/store.types'
// import { getOrders } from '../../../redux/reducers/order-reducer'
// import { useEffect } from 'react'
// const AdminOrders = () => {
//   return (
//     <div>
      
//     </div>
//   )
// }

// let mapStateToProps=(state:RootState)=>{
//     return{
//         orders: state.order.orders,
//         isFetching: state.order.isFetching
//     }
// }

// let mapDispatchToProps = {
//     getOrders,
// }

// export default connect()(AdminOrders)
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { RootState } from '../../../types/store.types'
import { getOrderDetails, getOrders } from '../../../redux/reducers/order-reducer';
import style from './AdminOrders.module.css'; // Your AdminOrder styles
import { MdDelete } from "react-icons/md";
import { FaPencilAlt } from "react-icons/fa";
import { orderApi } from '../../../api/api';
import { OrderDetailsType, OrdersType } from '../../../types/order.types';
import ConfirmDeleteModal from '../../UI/ConfirmDeleteModal/ConfirmDeleteModal';
import AlertMessage from '../../UI/AlertMessage/AlertMessage';
import OrderDetail from './OrderDetail/OrderDetail';
 // ConfirmDeleteModal component

interface AdminOrderProps{
    orders:OrdersType[]
    getOrders: () =>void
    isFetching:boolean
    singleOrder: OrderDetailsType
}

const AdminOrder = (props: AdminOrderProps) => {
  const [isProductModalOpen, setIsProductModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false); // State for delete confirmation modal
  const [selectedOrder, setSelectedOrder] = useState<number>(0);
  const [editingStatusOrderId, setEditingStatusOrderId] = useState<number | null>(null);
  const [alertMessage, setAlertMessage] = useState<string | null>(null); // Alert message state
  const [orderToDelete, setOrderToDelete] = useState<{ id: number, name: string } | null>(null); // Track the order to be deleted

  useEffect(() => {
    props.getOrders(); // Fetch orders on component mount
  }, []);

  // Helper function to format date
  const formatDate = (dateString: string) => {
    const options: Intl.DateTimeFormatOptions = {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      hour12: false,
    };
    return new Date(dateString).toLocaleString('uk-UA', options);
  };

  const handleDetailsClick = (id: number) => {
    setSelectedOrder(id);
    setIsProductModalOpen(true);
  };

  const handleOrderDeleteRequest = (id: number, name: string) => {
    setOrderToDelete({ id, name }); // Set the order to be deleted
    setIsDeleteModalOpen(true); // Open confirmation modal
  };

  const confirmDeleteOrder = () => {
    if (orderToDelete) {
      orderApi.deleteOrder(orderToDelete.id)
        .then(() => {
          setAlertMessage(`Замовлення "${orderToDelete.name}" успішно видалено.`);
          props.getOrders(); // Refresh the orders list after deletion
          setTimeout(() => setAlertMessage(null), 3000); // Hide alert after 3 seconds
        })
        .catch((err) => {
          setAlertMessage(`Не вдалося видалити замовлення "${orderToDelete.name}".`);
          setTimeout(() => setAlertMessage(null), 3000); // Hide alert after 3 seconds
        });
    }
    setIsDeleteModalOpen(false); // Close the confirmation modal
  };

  const cancelDeleteOrder = () => {
    setIsDeleteModalOpen(false); // Close the confirmation modal without deleting
  };

  // Function to handle the change of status
  const handleStatusChange = async (e: React.ChangeEvent<HTMLSelectElement>, orderId: number) => {
    let statusOrder = e.target.value
    try {
        const singleOrder:OrderDetailsType = await orderApi.getOrderDetails(orderId);
        const order = {
            order_id: singleOrder.order_id,
            address: singleOrder.guest.address,
            city:singleOrder.guest.city,
            delivery_method: singleOrder.guest.delivery_method,
            products: singleOrder.products,
            total_price: singleOrder.total,
            payment_method: singleOrder.guest.payment_method,
            status: statusOrder,
            guest_name: singleOrder.guest.name,
            guest_email: singleOrder.guest.email,
            guest_phone: singleOrder.guest.phone
        }
        console.log(order)
        await orderApi.updateOrder(orderId, order);

        setAlertMessage(`Статус замовлення ${orderId} оновлено на: ${statusOrder}`);
        await props.getOrders(); // Obnovte seznam objednávek
    } catch (err) {
        setAlertMessage(`Не вдалося оновити статус для замовлення ${orderId}.`);
    } finally {
        setTimeout(() => setAlertMessage(null), 3000);
        setEditingStatusOrderId(null)
    }
    
};


  return (
    <div className={style.container}>
      <h2 className={style.title}>Замовлення</h2>

      {/* Display alert if there's a message */}
      {alertMessage && <AlertMessage message={alertMessage} />}

      {props.isFetching ? (
        <p>Завантаження...</p>
      ) : (
        <table className={style.orderTable}>
          <thead>
            <tr>
              <th>ID</th>
              <th>Статус</th>
              <th>Дата створення</th>
              <th>Деталі замовлення</th>
              <th>Ціна</th>
              <th>Видалити</th>
            </tr>
          </thead>
          <tbody>
            {props.orders.map((order:OrdersType) => (
              <tr key={order.order_id}>
                <td>{order.order_id}</td>
                <td>
                  {editingStatusOrderId === order.order_id ? (
                    <select
                      value={order.status}
                      onChange={(e) => handleStatusChange(e, order.order_id)}
                      className={style.statusSelect}
                    >
                      <option value="Нове">Нове</option>
                      <option value="В обробці">В обробці</option>
                      <option value="Завершене">Завершене</option>
                    </select>
                  ) : (
                    <>
                      {order.status}
                      <FaPencilAlt
                        className={style.icon}
                        onClick={() => setEditingStatusOrderId(order.order_id)}
                      />
                    </>
                  )}
                </td>
                <td>{formatDate(order.created_at)}</td>
                <td>
                  <button onClick={() => handleDetailsClick(order.order_id)} className={style.modalButton}>
                    Детальніше про замовлення
                  </button>
                </td>
              
                <td>
                    {order.total} грн
                </td>
                <td style={{display:'flex', justifyContent:'center'}}>
                  <MdDelete className={style.delete} onClick={() => handleOrderDeleteRequest(order.order_id, `${order.order_id}`)} />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    
  
        {isProductModalOpen? <OrderDetail order_id={selectedOrder} onClose={() => setIsProductModalOpen(false)}></OrderDetail>:null}
      {isDeleteModalOpen && orderToDelete && (
        <ConfirmDeleteModal
          name={orderToDelete.name}
          onConfirm={confirmDeleteOrder}
          onCancel={cancelDeleteOrder}
        />
      )}
    </div>
  );
};

let mapStateToProps = (state: RootState) => {
  return {
    orders: state.order.orders,
    isFetching: state.order.isFetching,
    singleOrder: state.order.singleOrder
  };
};

let mapDispatchToProps = {
  getOrders: getOrders,
};

export default connect(mapStateToProps, mapDispatchToProps)(AdminOrder);
