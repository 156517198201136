import React, { useEffect } from 'react';
import Header from '../UI/Header/Header';
import Hero from './Hero/Hero';
import AboutUs from './AboutUs/AboutUs';
import Newest from './Newest/Newest';

const Home = () => {
  useEffect(() => {
    // Nastavení overflow-y: hidden při zobrazení stránky
    document.body.style.touchAction = 'pan-y';

    // Vrátíme overflow-y zpět na původní hodnotu při opuštění stránky
    return () => {
      document.body.style.touchAction = 'auto';
    };
  }, []);

  return (
    <main>
      <Hero />
      <AboutUs />
      <Newest />
    </main>
  );
};

export default Home;
