import React from 'react';
import styles from './Hero.module.css';
import forma from '../../../assets/images/forma.jpg';
import forma2 from '../../../assets/images/forma2.jpg';
import forma3 from '../../../assets/images/forma3.jpg';
import { FiTruck } from "react-icons/fi";
import { FiBox } from "react-icons/fi";
import { AiOutlineLike } from "react-icons/ai";
import { LuPercent } from "react-icons/lu";
import { FaGift } from 'react-icons/fa';
import { FaBoxOpen } from 'react-icons/fa';




const Hero = () => {
    return (
        <section className={styles.hero}>
            <div className={styles.container}>
                <div className={styles.top}>
                    <div className={styles.leftSide}>
                        <div className={styles.imageContainer}>
                            <img src={forma} alt='forma' className={styles.formaImage} />
                        </div>
                        <div className={styles.text}>
                            <div className={styles.titleContainer}>
                                <FiTruck className={styles.icon} />
                                <p className={styles.title}>Швидка доставка</p>

                            </div>
                            <div className={styles.titleContainer}>
                                <FiBox className={styles.icon} />
                                <p className={styles.title}>Товар на складі</p>
                            </div>
                            <div className={styles.titleContainer}>
                                <AiOutlineLike className={styles.icon} />
                                <p className={styles.title}>Якість гарантована</p>
                            </div>
                        </div>
                    </div>
                    <div className={styles.rightSide}>
                        <img src={forma2} alt='forma' className={styles.rightImage} />
                    </div>
                </div>
                <div className={styles.bottom}>
                    <div className={styles.leftImageContainer}>
                        <img src={forma3} alt='forma' className={styles.left} />
                    </div>
                    <div className={styles.rightSideBottom}>
                        <div className={styles.titleContainerRight}>
                            <LuPercent className={styles.icon} />
                            <p className={styles.title}>Низькі ціни</p>

                        </div>
                        <div className={styles.titleContainerRight}>
                            <FaGift className={styles.icon} />
                            <p className={styles.title}>Унікальний товар</p>
                        </div>
                        <div className={styles.titleContainerRight}>
                            <FaBoxOpen className={styles.icon} />
                            <p className={styles.title}>Великий вибір</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Hero;
