import React, { useEffect,useState } from 'react';
import { connect } from 'react-redux';
import { RootState } from '../../../types/store.types';
import { CategoryType } from '../../../types/category.types';
import style from './AdminCategories.module.css';
import ConfirmDeleteModal from '../../UI/ConfirmDeleteModal/ConfirmDeleteModal';
import { deleteCategory, getCategories } from '../../../redux/reducers/category-reducer';
import AlertMessage from '../../UI/AlertMessage/AlertMessage';
import { useNavigate } from 'react-router-dom';


interface AdminCategoriesProps {
    categories: CategoryType[];
    isFetching: boolean;
    getCategories: () => void;
    deleteCategory: (id: number) => void;
}

const AdminCategories = (props: AdminCategoriesProps) => {
  let navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false); // Track modal state
  const [selectedProduct, setSelectedProduct] = useState<{ category_id: number, name: string } | null>(null); // Track the product to delete
  const [isAlertVisible, setIsAlertVisible] = useState(false); // Track alert visibility
  const [deleteMessage, setAlertMessage] = useState(''); // Store alert message
  useEffect(() => {
    props.getCategories();
  }, []);

  const handleEdit = (id: number) => {
    navigate(`/admin/edit-category/${id}`);
    
  };
  const onAddCategoryHandler = () => {
    navigate('/admin/add-category');
  }  

  const handleDelete = (category:CategoryType) => {
    setSelectedProduct(category);
    setIsModalOpen(true);
  };
  const confirmDelete = async () => {
    if (selectedProduct) {
      try {
        await props.deleteCategory(selectedProduct.category_id);
        setIsModalOpen(false);
        setAlertMessage(`Категорія "${selectedProduct.name}" була успішно видалена.`);
        setIsAlertVisible(true);
        props.getCategories();
        setTimeout(() => setIsAlertVisible(false), 3000);
      } catch (error) {
        console.error('Error deleting category:', error);
      }
    }
  }
  const cancelDelete = () => {
    setIsModalOpen(false);
  }

  return (
    <div className={style.container}>
      <div className={style.header}>
        <h2>Управління категоріями</h2>
        <button className={style.addCategoryBtn} onClick={onAddCategoryHandler}>Додати категорію</button>
      </div>
      {isAlertVisible && <AlertMessage message={deleteMessage} />}
      {props.isFetching ? (
        <p>Завантаження...</p>
      ) : (
        <table className={style.categoryTable}>
          <thead>
            <tr>
              <th>#</th>
              <th>Назва</th>
              
              <th>Дії</th>
            </tr>
          </thead>
          <tbody>
            {props.categories.map((category, index) => (
              <tr key={category.category_id}>
                <td>{index + 1}</td>
                <td>{category.name}</td>
                <td>
                  <button onClick={() => handleEdit(category.category_id)} className={style.actionBtn}>
                    ✏️
                  </button>
                  <button onClick={() => handleDelete({category_id: category.category_id, name:category.name})} className={style.actionBtn}>
                    🗑️
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
      {isModalOpen && selectedProduct && (
        <ConfirmDeleteModal
          name={selectedProduct.name}
          onConfirm={confirmDelete}
          onCancel={cancelDelete}
        />
      )}
    </div>
  );
};

const mapStateToProps = (state: RootState) => {
  return {
    categories: state.category.categories,
    isFetching: state.category.isFetching
  };
};

const mapDispatchToProps = {
  getCategories,
  deleteCategory: deleteCategory,
  
};

export default connect(mapStateToProps, mapDispatchToProps)(AdminCategories);
