import React, { useState,useRef } from 'react';
import { Inventory, ItemByCategory } from '../../../types/item.types';
import styles from './CategoryItem.module.css';
import { GoStar, GoStarFill } from "react-icons/go";
import { likeItem, unlikeItem } from '../../../redux/reducers/liked-reducer';
import aboutUs1 from '../../../assets/images/aboutUs1.jpg';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { itemApi } from '../../../api/api';
import { Select, MenuItem, FormControl, InputLabel, Button } from '@mui/material';
import { addToCart } from '../../../redux/reducers/cart-reducer';
import AlertMessage from '../AlertMessage/AlertMessage';

interface CategoryItemProps {
    item: ItemByCategory;
    isLiked?: boolean;
    likeItem: (id: number) => void;
    unlikeItem: (id: number) => void;
    addToCart: (product_id: number, color: string, size: string, quantity: number) => void
}

const CategoryItem = (props: CategoryItemProps) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [colors, setColors] = useState<Inventory[]>([]);
    const [selectedColor, setSelectedColor] = useState<string | null>(null);
    const [selectedSize, setSelectedSize] = useState<string | null>(null);
    const [isFetching, setIsFetching] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<string>("");
    const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false); // Lokální stav
    const [successMessage, setSuccessMessage] = useState<string | null>(null);


    let usableMedia = props.item.media.map((media:any)=>{ 
        if(media.color !== "" && media.color!==null && media.media_type!=="video" && media.color!=="null"){
            console.log(media.media_url);
            return media.media_url
        }
    }).filter((url:any)=>url !== undefined)
   
    console.log(usableMedia);
    const handleStarClick = () => {
        if (props.isLiked) {
            props.unlikeItem(props.item.product_id);
        } else {
            props.likeItem(props.item.product_id);
        }
    };

    const handleOpenModal = async (id: number) => {
        setIsModalOpen(true);
        setIsFetching(true);
        const response = await itemApi.getItem(id);
        const colors = response.inventory;
        setColors(colors);
        setIsFetching(false);
        
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
        setSelectedColor(null);
        setSelectedSize(null);
        setErrorMessage("");
    };

    const handleBuy = async (id: number) => {
        if (!selectedColor) {
            setErrorMessage("Будь ласка, виберіть колір!");
            return;
        }
        if (!selectedSize) {
            setErrorMessage("Будь ласка, виберіть розмір!");
            return;
        }

        try {
            // Asynchronní akce pro přidání do košíku
            await props.addToCart(Number(id), selectedColor, selectedSize, 1);

            // Zobrazit modal s úspěšnou zprávou
            alert('Товар було додано')
            
            // Automatické zavření po 3 sekundách
            setTimeout(() => {
                setIsSuccessModalOpen(false);
            }, 3000);

        } catch (error) {
            console.error("Error adding to cart:", error);
            alert("Помилка під час додавання товару до кошика");
        }
    };

    return (
        <article className={styles.item}>
            {isSuccessModalOpen && successMessage && (<AlertMessage message={successMessage || ''} />)}
            {/* Modal Window */}
            {isModalOpen && (
                <div className={styles.back}>
                    <div className={styles.modalContent}>
                        {!isFetching && colors ? (
                            <div>
                                <FormControl fullWidth margin="normal">
                                    <InputLabel id="color-select-label">Виберіть колір</InputLabel>
                                    <Select
                                        labelId="color-select-label"
                                        value={selectedColor || ""}
                                        onChange={(e) => {
                                            setSelectedColor(e.target.value);
                                            setSelectedSize(null); // Reset size when color changes
                                            setErrorMessage("");
                                        }}
                                    >
                                        {colors.map((color) => (
                                            <MenuItem key={color.color} value={color.color}>
                                                {color.color}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>

                                {selectedColor && (
                                    <FormControl fullWidth margin="normal">
                                        <InputLabel id="size-select-label">Виберіть розмір</InputLabel>
                                        <Select
                                            labelId="size-select-label"
                                            value={selectedSize || ""}
                                            onChange={(e) => {
                                                setSelectedSize(e.target.value);
                                                setErrorMessage("");
                                            }}
                                        >
                                            {colors
                                                .find((color) => color.color === selectedColor)
                                                ?.sizes.map((size) => (
                                                    <MenuItem key={size.size} value={size.size}>
                                                        {size.size} (на складі: {size.stock})
                                                    </MenuItem>
                                                ))}
                                        </Select>
                                    </FormControl>
                                )}
                            </div>
                        ) : (
                            <p>Завантаження...</p>
                        )}
                        {errorMessage && <p className={styles.error}>{errorMessage}</p>}
                        <div className={styles.buttons}>

                            <button
                                color="primary"
                                onClick={()=>handleBuy(props.item.product_id)}
                                className={styles.buyNowButton}
                            >
                                Купити
                            </button>
                            <button className={styles.closeButton} onClick={handleCloseModal}>x</button>
                        </div>
                    </div>
                </div>
            )}

            {/* Product Card */}
            <div className={styles.imageContainer}>
                <NavLink to={`/product/${props.item.product_id}`} className={styles.link}>
                    <img
                        src={usableMedia[0]}
                        alt={props.item.name}
                        className={styles.image}
                    />
                </NavLink>
                {props.item.isNew && <div className={styles.new}>Новинка</div>}
                <div className={styles.starContainer} onClick={handleStarClick}>
                    {props.isLiked ? (
                        <GoStarFill className={`${styles.star} ${styles.liked}`} />
                    ) : (
                        <GoStar className={`${styles.star} ${styles.unliked}`} />
                    )}
                </div>
            </div>

            {/* Product Content */}
            <div className={styles.contentContainer}>
                <div className={styles.container}>
                    <p className={styles.title}>{props.item.name}</p>
                    <p className={styles.price}>{props.item.price} грн</p>
                </div>
                <button className={styles.button} onClick={() => handleOpenModal(props.item.product_id)}>
                    Купити
                </button>
            </div>
        </article>
    );
};

const mapDispatchToProps = {
    likeItem,
    unlikeItem,
    addToCart
};

export default connect(null, mapDispatchToProps)(CategoryItem);
