import React, { FormEvent, useEffect, useState } from 'react';
import styles from './Login.module.css';
import { useNavigate } from 'react-router-dom';
import { authApi } from '../../api/api';
const Login = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState({ email: '', password: '', form: '' });
    useEffect(() => {
      isAdminHandler();
    }, []);
    const isAdminHandler = async () => {
      await authApi
          .isAdmin()
          .then((data) => {
            if (data.isAdmin) {
              navigate('/admin');
            }
          })
     }
  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();


   
    // Reset errors
    setError({ email: '', password: '', form: '' });

    let hasError = false;

    // Validate email
    if (!email) {
      setError((prev) => ({ ...prev, email: 'Будь ласка, введіть електронну адресу.' }));
      hasError = true;
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      setError((prev) => ({ ...prev, email: 'Будь ласка, введіть правильну електронну адресу.' }));
      hasError = true;
    }

    // Validate password
    if (!password) {
      setError((prev) => ({ ...prev, password: 'Будь ласка, введіть пароль.' }));
      hasError = true;
    }

    if (hasError) return;

    // Submit form data
    try {
        const data = await authApi.login(email, password); 
        localStorage.setItem("token", data.token); 
        window.location.reload();
        
    } catch (e) {
        console.log(e);
        // Zpracování chyb, například nastavení chybového hlášení
        setError((prev) => ({ ...prev, password: "Помилка при вході, спробуйте ще раз." }));
    }
  };

  return (
    <div className={styles.loginContainer}>
      <form className={styles.loginForm} onSubmit={handleSubmit}>
        <h2 className={styles.loginTitle}>Вхід</h2>

        {error.form && <p className={styles.errorMessage}>{error.form}</p>}

        <div className={styles.formGroup}>
          <label htmlFor="email">Електронна адреса</label>
          <input
            type="email"
            id="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Введіть вашу електронну адресу"
            className={styles.input}
          />
          {error.email && <p className={styles.fieldError}>{error.email}</p>}
        </div>

        <div className={styles.formGroup}>
          <label htmlFor="password">Пароль</label>
          <input
            type="password"
            id="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            placeholder="Введіть ваш пароль"
            className={styles.input}
          />
          {error.password && <p className={styles.fieldError}>{error.password}</p>}
        </div>

        <button type="submit" className={styles.loginButton}>
          Увійти
        </button>
      </form>
    </div>
  );
};

export default Login;
