import React, { useEffect } from 'react'
import styles from './Liked.module.css'
import { connect } from 'react-redux'
import { RootState } from '../../types/store.types'
import { getLikedItems } from '../../redux/reducers/liked-reducer'
import LikedItem from '../UI/LikedItem/LikedItem'
import { LikedItems } from '../../types/liked.types'
import { Media } from '../../types/item.types'
import { FaSadTear } from 'react-icons/fa' // Import sad icon from react-icons
import SkeletonLoader from '../UI/SkeletonLoader/SkeletonLoader'

interface LikedProps {
    likedItems: LikedItems
    isLikedFetching: boolean
    getLikedItems: () => void
}
interface LikedItem {
    product_id: number
    name: string
    description: string
    price: string
    stock: number
    category_id: number
    media: Media[];
}

const Liked = (props: LikedProps) => {
    useEffect(() => {
        props.getLikedItems()
    }, [])

    return (
        <main>
            {/* Conditionally render the heading if there are liked items */}
            {props.likedItems.favorites.length > 0 && (
                <h2 className={styles.title}>Обрані товари</h2>
            )}
            <div className={styles.likedContainer}>
                {props.isLikedFetching ? (
                   <SkeletonLoader length={10}></SkeletonLoader>
                ) : props.likedItems.favorites.length > 0 ? (
                    props.likedItems.favorites.map((item: LikedItem) => (
                        <LikedItem key={item.product_id} item={item} />
                    ))
                ) : (
                    <div className={styles.noLikedItems}>
                        <FaSadTear className={styles.sadIcon} />
                        <p>Немає обраних товарів</p>
                    </div>
                )}
            </div>
        </main>
    )
}

let mapStateToProps = (state: RootState) => {
    return {
        likedItems: state.liked.likedItems,
        isLikedFetching: state.liked.isFetching
    }
}

let mapDispatchToProps = {
    getLikedItems: getLikedItems
}

export default connect(mapStateToProps, mapDispatchToProps)(Liked)
