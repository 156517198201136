import { ItemType } from "../../types/item.types"; // Assuming ItemType is defined here
import { itemApi } from "../../api/api";       // Assuming itemApi is properly typed
import { ItemsByCategory } from "../../types/item.types";
import { ProductType } from "../../types/product.types";
import { SimilarProducts } from "../../types/similar.types";

// Action types
const SET_ITEMS = 'SET_ITEMS';
const SET_IS_FETCHING = 'SET_IS_FETCHING';
const SET_CATEGORY_ITEMS = 'SET_CATEGORY_ITEMS';
const SET_SINGLE_ITEM = 'SET_SINGLE_ITEM';
const UNSET_SINGLE_ITEM = 'UNSET_SINGLE_ITEM';
const SET_SIMILAR_PRODUCTS = 'SET_SIMILAR_PRODUCTS';
// Initial state type
interface StateType {
  items: ItemType[]; // Array of items based on ItemType
  isFetching: boolean;
  categoryItems: ItemsByCategory;
  singleItem: ProductType;
  similarProducts: SimilarProducts;
}

// Define initial state
const initialState: StateType = {
  items: [],
  isFetching: false,
  categoryItems: {
    page: 0,
    totalPages: 0,
    totalProducts: 0,
    products: []
  },
  singleItem: {
    product_id: 0,
    name: '',
    description: '',
    price: '',
    category_id: 0,
    created_at: '',
    average_rating: '',
    media: {},
    inventory: [],
    reviews: []
  },
  similarProducts: {
    products: []
  }
};

// Define action type with generics for payload type safety
interface ActionType<T = any> {
  type: string;
  payload: T;
}

// Reducer
export const itemReducer = (state = initialState, action: ActionType): StateType => {
  switch (action.type) {
    case SET_ITEMS:
      return {
        ...state,
        items: action.payload as ItemType[] // Ensure payload is typed as Item array
      };
    case SET_IS_FETCHING:
      return {
        ...state,
        isFetching: action.payload as boolean
      };
    case SET_CATEGORY_ITEMS:
        return {
            ...state,
            categoryItems: action.payload as ItemsByCategory
        }
    case SET_SINGLE_ITEM:
        return {
            ...state,
            singleItem: action.payload as ProductType
        }
    case UNSET_SINGLE_ITEM:
        return {
            ...state,
            singleItem: initialState.singleItem
        }
    case SET_SIMILAR_PRODUCTS:
        return {
            ...state,
            similarProducts: action.payload as SimilarProducts
        }
    default:
      return state;
  }
};

// Action creators with type
const setItems = (items: ItemType[]): ActionType<ItemType[]> => ({
  type: SET_ITEMS,
  payload: items
});
const setIsFetching = (bool: boolean): ActionType<boolean> => ({
  type: SET_IS_FETCHING,
  payload: bool
});

const setCategoryItems = (items: ItemsByCategory): ActionType<ItemsByCategory> => ({
    type: SET_CATEGORY_ITEMS,
    payload: items
});

const setSingleItem = (item: ProductType): ActionType<ProductType> => ({
    type: SET_SINGLE_ITEM,
    payload: item
});
export const unsetSingleItem = (): ActionType => ({
    type: UNSET_SINGLE_ITEM,
    payload: null
})

export const getItems = () => async (dispatch: (action: ActionType) => void) => {
  dispatch(setIsFetching(true));
  try {
    const data: ItemType[] = await itemApi.getItems(); // Assuming itemApi.getItems() returns Item[]
    dispatch(setItems(data));
  } finally {
    dispatch(setIsFetching(false));
  }
};

export const getItem = (id: number) => async (dispatch: (action: ActionType) => void) => {
    dispatch(setIsFetching(true));
    try {
        const data: ProductType = await itemApi.getItem(id); // Assuming itemApi.getItem(id) returns ProductType
        dispatch(setSingleItem(data));
    } finally {
        dispatch(setIsFetching(false));
    }
};

export const getItemsByCategory = (categoryId: number, page:number) => async (dispatch: (action: ActionType) => void) => {
    dispatch(setIsFetching(true));
    try {
        const data: ItemsByCategory = await itemApi.getItemsByCategory(categoryId,page); // Assuming itemApi.getItemsByCategory(categoryId) returns ItemsByCategory

        dispatch(setCategoryItems(data));
    } finally {
        dispatch(setIsFetching(false));
    }
}
export const getFilteredItems = (categoryId: number, min_price?:number,max_price?:number,color?:string,size?:string,sort_by?:string,order?:string,page?:number) => async (dispatch: (action: ActionType) => void) => {

    dispatch(setIsFetching(true));
    try {
        const data = await itemApi.getFilteredItems(categoryId,min_price,max_price,color,size,sort_by,order, page); // Assuming itemApi.getFilteredItems(categoryId,min_price,max_price,color,size,sort_by,order) returns ItemsByCategory
        console.log(data);
        dispatch(setCategoryItems(data));
    } finally {
        dispatch(setIsFetching(false));
    }
}

export const getRelatedItems = (productId: number) => async (dispatch: (action: ActionType) => void) => {
    dispatch(setIsFetching(true));
    try {
        const data = await itemApi.getRelatedItems(productId); 
        dispatch({
            type: SET_SIMILAR_PRODUCTS,
            payload: data
        });
    } finally {
        dispatch(setIsFetching(false));
    }
}
export const getNewItems = () => async (dispatch: (action: ActionType) => void) => {
    dispatch(setIsFetching(true));
    try {
        const data = await itemApi.getNewItems();
        dispatch(setItems(data));
    } finally {
        dispatch(setIsFetching(false));
    }
}

export const deleteItem = (id: number) => async (dispatch: any) => {
    dispatch(setIsFetching(true));
    try {
        await itemApi.deleteItem(id);
        dispatch(getItems());
    } finally {
        dispatch(setIsFetching(false));
    }
}

export const addProduct = (product: any) => async (dispatch: any) => {
    dispatch(setIsFetching(true));
    try {
        await itemApi.addProduct(product);
        dispatch(getItems());
    } finally {
        dispatch(setIsFetching(false));
    }
}

export const updateProduct = (id:number, product: any) => async (dispatch: any) => {
    dispatch(setIsFetching(true));
    try {
        await itemApi.updateProduct(id, product);
        dispatch(getItems());
    } finally {
        dispatch(setIsFetching(false));
    }
}