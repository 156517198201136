import React from 'react';
import style from './ConfirmDeleteModal.module.css';

interface ConfirmDeleteModalProps {
  name: string;
  onConfirm: () => void;
  onCancel: () => void;
}

const ConfirmDeleteModal: React.FC<ConfirmDeleteModalProps> = ({ name, onConfirm, onCancel }) => {
  return (
    <div className={style.modalOverlay}>
      <div className={style.modal}>
        <h2>Підтвердіть видалення</h2>
        <p>Ви дійсно хочете видалити "<strong>{name}</strong>"?</p>
        <div className={style.modalActions}>
          <button onClick={onConfirm} className={style.confirmBtn}>Так, видалити</button>
          <button onClick={onCancel} className={style.cancelBtn}>Скасувати</button>
        </div>
      </div>
    </div>
  );
};

export default ConfirmDeleteModal;
