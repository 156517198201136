import React, { useRef } from 'react';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import styles from './Newest.module.css';
import Item from '../../UI/Item/Item';
import { ItemCard, ItemType } from '../../../types/item.types';
import { useMediaQuery } from '@uidotdev/usehooks';
import { FaChevronLeft } from "react-icons/fa6";
import { FaChevronRight } from "react-icons/fa6";
import { useEffect } from 'react';
import { connect } from 'react-redux';
import { RootState } from '../../../types/store.types';
import { getNewItems } from '../../../redux/reducers/item-reducer';

// const data: ItemCard[] = [
//   { id: 1, title: "Новинка 1", price: "100$", image: "http://localhost:3000/static/media/aboutUs1.72330d97a9214199fc69.jpg" },
//   { id: 2, title: "Новинка 2", price: "300$", image: "http://localhost:3000/static/media/aboutUs2.5ef7975a228eb614efdf.jpg" },
//   { id: 3, title: "Новинка 3", price: "200$", image: "http://localhost:3000/static/media/aboutUs3.8ab4dffec7cf316fa33c.jpg" }
// ];

interface NewestProps {
  items: ItemType[]
  isFetching: boolean
  getNewItems: () => void
}


const gap = 25;

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1238 },
    items: 3,
  },
  tablet: {
    breakpoint: { max: 1237, min: 851 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 850, min: 0 },
    items: 1,
  },
};

const Newest = (props:NewestProps) => {
  const is500Px = useMediaQuery('(min-width: 401px) and (max-width: 500px)');
  const is400px = useMediaQuery('(max-width: 400px)');
  let itemWidth = is500Px ? 300 : is400px ? 240 : 370; 
  useEffect(() => {
    props.getNewItems()
  }, []);
  const carouselRef = useRef<Carousel>(null); // Create a ref to the Carousel component

  const isDesktop = useMediaQuery('(min-width: 1238px)');
  const isTablet = useMediaQuery('(min-width: 850px) and (max-width: 1237px)');

  const itemsToShow = isDesktop ? 3 : isTablet ? 2 : 1;
  const containerWidth = itemWidth * itemsToShow + gap * (itemsToShow - 1);

  // Function to slide left
  const slideLeft = () => {
    carouselRef.current?.previous(1); // Use the built-in previous method
  };

  // Function to slide right
  const slideRight = () => {
    carouselRef.current?.next(1); // Use the built-in next method
  };

  return (
    <section>
      <h2 className={styles.title}>Новинки</h2>
      <div className={styles.carouselContainer} style={{ width: `${containerWidth}px` }}>
        <Carousel
          ref={carouselRef}
          responsive={responsive}
          infinite={true}
          itemClass={styles.carouselItem}
          arrows={false}
        >
         {
          props.isFetching ? <p>Loading...</p> : props.items.map(item => {
        
            return (
              <Item key={item.product_id} item={item}/>
            )
          })
         }
        </Carousel>
        
        {/* Custom Navigation Buttons */}
        <button className={styles.arrow} onClick={slideLeft} aria-label="Previous">
          <FaChevronLeft /> {/* Left Arrow */}
        </button>
        <button className={styles.arrow} onClick={slideRight} aria-label="Next">
          <FaChevronRight /> {/* Right Arrow */}
        </button>
      </div>
    </section>
  );
};

let mapStateToProps = (state: RootState) => {
  return {
    items: state.item.items,
    isFetching: state.item.isFetching
  };
}

let mapDispatchToProps ={
  getNewItems: getNewItems,
}

export default connect(mapStateToProps, mapDispatchToProps)(Newest);
