import { CartType } from "../../types/cart.types";
import { cartApi } from "../../api/api";
const SET_CART = 'SET_CART';
const SET_IS_FETCHING = 'SET_IS_FETCHING';

interface StateType {
  cart: CartType[];
  isFetching: boolean;
}

const initialState: StateType = {
  cart: [],
  isFetching: false
};

interface ActionType<T = any> {
  type: string;
  payload: T;
}

export const cartReducer = (state: StateType = initialState, action: ActionType<any[] | boolean>): StateType => {
  switch (action.type) {
    case SET_CART:
      return {
        ...state,
        cart: action.payload as any[]
      };
    case SET_IS_FETCHING:
      return {
        ...state,
        isFetching: action.payload as boolean
      };
    default:
      return state;
  }
};

const setCart = (cart: CartType[]): ActionType<any[]> => ({
  type: SET_CART,
  payload: cart
});

const setIsFetching = (isFetching: boolean): ActionType<boolean> => ({
  type: SET_IS_FETCHING,
  payload: isFetching
});

export const getCart = () => async (dispatch: any) => {
  dispatch(setIsFetching(true));
  try {
    let data = await cartApi.getCart(); 
    if(data.cart?.length === 0){
      data = [];
    }
    dispatch(setCart(data));
  } finally {
    dispatch(setIsFetching(false));
  }
};

export const addToCart = (product_id: number, color: string, size: string, quantity: number) => async (dispatch: any) => {
  dispatch(setIsFetching(true));
  try {
    await cartApi.addToCart(product_id, color, size, quantity);
    dispatch(getCart());
  } finally {
    dispatch(setIsFetching(false));
  }
}

export const updateCart = (product_id: number, color: string, size: string, quantity: number) => async (dispatch: any) => {
    dispatch(setIsFetching(true));
    try {
        await cartApi.updateCart(product_id, color, size, quantity);
        dispatch(getCart());
    } finally {
        dispatch(setIsFetching(false));
    }
}

export const removeFromCart = (product_id: number, color:string, size:string) => async (dispatch: any) => {
  dispatch(setIsFetching(true));
  try {
    await cartApi.removeFromCart(product_id,color, size);
    dispatch(getCart());
  } finally {
    dispatch(setIsFetching(false));
  }
}

export const clearCart = () => async (dispatch: any) => {
    dispatch(setIsFetching(true));
    try {
        await cartApi.clearCart();
        dispatch(getCart());
    } finally {
        dispatch(setIsFetching(false));
    }
}
