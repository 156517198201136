import React, { useEffect, useState, useRef } from 'react';
import styles from './Header.module.css';
import logo from '../../../assets/icons/logo.svg';
import { CiSearch } from 'react-icons/ci';
import starIcon from '../../../assets/icons/star.svg';
import cartIcon from '../../../assets/icons/cart.svg';
import { NavLink } from 'react-router-dom';
import { connect } from 'react-redux';
import { RootState } from '../../../types/store.types';
import { getCategories } from '../../../redux/reducers/category-reducer';
import { CategoryType } from '../../../types/category.types';
import { searchItems } from '../../../redux/reducers/search-reducer';
import { SearchType } from '../../../types/search.types';
import forma2 from '../../../assets/images/forma2.jpg';
import { useNavigate } from 'react-router-dom';
import CartModal from '../CartModal/CartModal';
import { getCart } from '../../../redux/reducers/cart-reducer';
import { CartType } from '../../../types/cart.types';
import { getLikedItems } from '../../../redux/reducers/liked-reducer';
import { LikedItems } from '../../../types/liked.types';
import { IoClose } from 'react-icons/io5';
import { useMediaQuery } from '@uidotdev/usehooks';
import { IoMenuOutline } from "react-icons/io5";




interface HeaderProps {
    categories: CategoryType[];
    isFetching: boolean;
    searchResults: SearchType;
    isSearchFetching: boolean;
    getCategories: () => void;
    searchItems: (query: string, page: number) => void;
    cart: CartType[];
    isCartFetching: boolean;
    getCart: () => void;
    likedItems: LikedItems;
    isLikedFetching: boolean;
    getLikedItems: () => void;
}

const Header = (props: HeaderProps) => {
    const [searchQuery, setSearchQuery] = useState<string>('');
    const [showDropdown, setShowDropdown] = useState<boolean>(false);
    const [mobileQuery, setMobileQuery] = useState<string>('');
    const dropdownRef = useRef<HTMLDivElement | null>(null);
    const [isSearchModalOpen, setIsSearchModalOpen] = useState<boolean>(false);
    const [showMobileDropdown, setShowMobileDropdown] = useState<boolean>(false);
    const inputRef = useRef<HTMLInputElement | null>(null);
    const [showCart, setShowCart] = useState<boolean>(false);
    const navigate = useNavigate();
    const isMobile = useMediaQuery('(max-width: 850px)');
    const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

    const toggleMobileMenu = () => {
        setMobileMenuOpen(!mobileMenuOpen);
    };
    useEffect(() => {
        props.getCategories();
        props.getCart();
        props.getLikedItems();
    }, []);

    const handleSearchInput = (event: React.ChangeEvent<HTMLInputElement>) => {
        const query = event.target.value;
        setSearchQuery(query);
        setMobileQuery(query);
        if (query) {
            setShowDropdown(true);
            setShowMobileDropdown(true);
            props.searchItems(query, 1);
        } else {
            setShowDropdown(false);
        }
    };

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        if(isMobile) {
            if(mobileQuery) {
                navigate(`/search/?q=${mobileQuery}`);
            }
        }
        else {
            if(searchQuery) {
                navigate(`/search/?q=${searchQuery}`);
            }
        }
        
        setSearchQuery('');
        setShowDropdown(false);
        setIsSearchModalOpen(false); // Close modal on submit
    };
    const onCloseModalHandler = () => {
        setShowCart(false);
    }

    // Handle clicks outside of the dropdown and input
    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (
                dropdownRef.current &&
                inputRef.current &&
                !dropdownRef.current.contains(event.target as Node) &&
                !inputRef.current.contains(event.target as Node)
            ) {
                setShowDropdown(false);
                setSearchQuery('');
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    return (
        <header className={styles.header}>
             

            {/* Sliding menu */}
            <div className={`${styles.mobileMenu} ${mobileMenuOpen ? styles.open : ''}`}>
                <IoClose className={styles.closeMenuIcon} onClick={toggleMobileMenu} />
                <ul className={styles.mobileNavigation}>
                    {props.categories.map((item) => (
                        <li key={item.category_id} className={styles.mobileNavItem}>
                            <NavLink
                                to={`/category/${item.category_id}`}
                                className={styles.mobileLink}
                                onClick={toggleMobileMenu}
                            >
                                {item.name}
                            </NavLink>
                        </li>
                    ))}
                </ul>
            </div>
            {isSearchModalOpen?  (
                <div className={styles.searchModalMobile}>
                    <IoClose className={styles.closeIcon} onClick={() => setIsSearchModalOpen(false)} />
                    <CiSearch className={styles.mobileSearchIcon} />
                    <form onSubmit={handleSubmit}>
                        <input
                          
                            type="text"
                            placeholder="пошук в каталозі"
                            className={styles.modalSearch}
                            value={searchQuery}
                            onChange={handleSearchInput}
                        />
                        {showMobileDropdown && mobileQuery && (
                            <div>
                                {props.isSearchFetching ? (
                                    <p>Loading...</p>
                                ) : props.searchResults.products.length === 0 ? (
                                    <p className={styles.noResults}>Нічого не знайдено</p>
                                ) : (
                                    props.searchResults.products.map((item) => (
                                        <NavLink
                                            to={`/product/${item.product_id}`}
                                            key={item.product_id}
                                            className={styles.searchItem}
                                            onClick={() => { setShowMobileDropdown(false); setIsSearchModalOpen(false); }}
                                        >
                                            <img
                                                src={item.media[0]?.media_url || forma2}
                                                alt={item.name}
                                                className={styles.searchImage}
                                            />
                                            <div>
                                                <p className={styles.searchItemName}>{item.name}</p>
                                                <p className={styles.searchItemPrice}>{item.price} грн</p>
                                            </div>
                                        </NavLink>
                                    ))
                                )}
                                {props.searchResults.products.length === 0 ? null :
                                    <div className={styles.buttonContainer}>
                                        <button className={styles.resultButton}>Всі результати</button>
                                    </div>
                                }
                            </div>
                        )}
                        
                    </form>
                </div>
            ): null}
            <div className={styles.mainHeader}>
                {
                    showCart && <CartModal onCloseModal={onCloseModalHandler} />
                }
                <NavLink to='/'><img src={logo} alt="logo" className={styles.logo} /></NavLink>
                
                <div className={styles.content}>

                    <form onSubmit={handleSubmit}>
                        <div className={styles.searchWrapper}>
                            <input
                                ref={inputRef}
                                type="text"
                                placeholder="Пошук..."
                                className={styles.search}
                                value={searchQuery}
                                onChange={handleSearchInput}
                            />
                            <CiSearch className={styles.searchIcon} />
                            {showDropdown && searchQuery && (
                                <div ref={dropdownRef} className={styles.searchDropdown}>
                                    {props.isSearchFetching ? (
                                        <p>Loading...</p>
                                    ) : props.searchResults.products.length === 0 ? (
                                        <p className={styles.noResults}>Нічого не знайдено</p>
                                    ) : (
                                        props.searchResults.products.map((item) => (
                                            <NavLink
                                                to={`/product/${item.product_id}`}
                                                key={item.product_id}
                                                className={styles.searchItem}
                                                onClick={() => setShowDropdown(false)}
                                            >
                                                <img
                                                    src={item.media[0]?.media_url || forma2}
                                                    alt={item.name}
                                                    className={styles.searchImage}
                                                />
                                                <div>
                                                    <p className={styles.searchItemName}>{item.name}</p>
                                                    <p className={styles.searchItemPrice}>{item.price} грн</p>
                                                </div>
                                            </NavLink>
                                        ))
                                    )}
                                    {props.searchResults.products.length === 0 ? null :
                                        <div className={styles.buttonContainer}>
                                            <button className={styles.resultButton}>Всі результати</button>
                                        </div>
                                    }
                                </div>
                            )}
                        </div>
                    </form>
                    <div className={styles.icons}>
                        <div className={styles.counterContainer}>
                            <button
                                className={styles.mobileSearchButton}
                                onClick={() => setIsSearchModalOpen(true)}
                            >
                                <CiSearch className={styles.searchIcon} />
                            </button>
                        </div>
                        <div className={styles.counterContainer}>
                            <NavLink to='/liked'><img src={starIcon} alt="star" className={styles.icon} /></NavLink>
                            {
                                props.likedItems.favorites.length > 0 && <div className={styles.likedCounter}>{props.likedItems.favorites.length}</div>
                            }
                        </div>
                        <div className={styles.counterContainer}>
                            <img src={cartIcon} alt="cart" className={styles.icon} onClick={() => setShowCart(true)} />
                            {
                                props.cart.length > 0 && <div className={styles.cartCounter}>{props.cart.length}</div>
                            }
                        </div>
                        {/* <NavLink to='/admin'><button className={styles.adminButton}>Admin</button></NavLink> */}
                    </div>
                </div>
            </div>
            <nav className={styles.categoryContainer}>
                <IoMenuOutline onClick={toggleMobileMenu} className={styles.barsIcon}></IoMenuOutline>
                <ul className={styles.navigation}>
                        
                    
                    {props.isFetching ? <p>Loading...</p> : props.categories.map((item) => (
                        
                        <li key={item.category_id} className={styles.navItem}>
                            <NavLink to={`/category/${item.category_id}`} className={styles.link}>{item.name}</NavLink>
                        </li>
                    ))}
                </ul>
            </nav>
        </header>
    );
};

const mapStateToProps = (state: RootState) => ({
    categories: state.category.categories,
    isFetching: state.category.isFetching,
    searchResults: state.search.searchResults,
    isSearchFetching: state.search.isFetching,
    cart: state.cart.cart,
    isCartFetching: state.cart.isFetching,
    likedItems: state.liked.likedItems,
    isLikedFetching: state.liked.isFetching,
});

const mapDispatchToProps = {
    getCategories,
    searchItems,
    getCart,
    getLikedItems,
};

export default connect(mapStateToProps, mapDispatchToProps)(Header);
