import { orderApi } from "../../api/api";
import { OrderDetailsType, OrderType, OrdersType } from "../../types/order.types";
const MAKE_ORDER = 'MAKE_ORDER';
const SET_ORDERS = 'SET_ORDERS';
const SET_FETCHING = 'SET_FETCHING';
const SET_SINGLE_ORDER='SET_SINGLE_ORDER'

const initialState = {
  orders: [],
  isFetching: false,
  singleOrder: {}
};

export const orderReducer = (state = initialState, action:any) => {
  switch (action.type) {
    case SET_ORDERS:
      return {
        ...state,
        orders: action.orders,
      };
    case SET_FETCHING:
      return {
        ...state,
        isFetching: action.isFetching,
      };
    case SET_SINGLE_ORDER:
        return {
        ...state,
        singleOrder: action.singleOrder
    }
    default:
      return state;
  }
};

export const setOrders = (orders: OrdersType[]) => {
  return {
    type: SET_ORDERS,
    orders,
  };
}
export const setFetching = (isFetching: boolean) => {
  return {
    type: SET_FETCHING,
    isFetching,
  };
}
export const setSingleOrder = (singleOrder: OrderDetailsType)=>{
    return {
        type:SET_SINGLE_ORDER,
        singleOrder
    }
}


export const makeOrder = (order: OrderType) => async (dispatch: any) => {
    dispatch(setFetching(true));
    try {
        await orderApi.makeOrder(order);
    } finally {
        dispatch(setFetching(false));
    }
}
export const getOrders = () => {
    return async (dispatch:any) => {
      try {
        dispatch(setFetching(true));
        const response = await orderApi.getOrders();
        dispatch(setOrders(response.reverse()));
        dispatch(setFetching(false));
      } catch (e) {
        console.log(e);
      }
    };
  }

export const getOrderDetails = (id:number) =>{
    return async (dispatch:any) => {
        try {
          dispatch(setFetching(true));
          const response = await orderApi.getOrderDetails(id);
          dispatch(setSingleOrder(response));
          dispatch(setFetching(false));
        } catch (e) {
          console.log(e);
        }
      };
}