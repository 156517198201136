import React from 'react';
import style from './AlertMessage.module.css';

interface AlertMessageProps {
  message: string;
  backgroundColor?: string;
}

const AlertMessage: React.FC<AlertMessageProps> = ({ message, backgroundColor="#d4edda"}) => {
  return (
    <div className={style.alert} style={{backgroundColor:backgroundColor}}>
      <p>{message}</p>
    </div>
  );
};

export default AlertMessage;
