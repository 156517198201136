import axios from 'axios';
import { get } from 'http';
import { OrderDetailsType, OrderType } from '../types/order.types';
import { getOrders } from '../redux/reducers/order-reducer';
const api = axios.create({
  baseURL: 'https://api.tactical-forma.com/api',
//   baseURL: 'http://localhost:3001/api',
  withCredentials: true,
  headers: {
    'Authorization': 'Bearer ' + localStorage.getItem('token')
    },
});


export const itemApi = {
    async getItems() {
        return api.get('/products/').then(res => res.data);
    },
    async getItem(id: number) {
        return api.get(`/products/${id}`).then(res => res.data);
    },
    async getItemsByCategory(categoryId: number, page: number) {
        return api.get(`/products/category/${categoryId}?limit=8&page=${page}`).then(res => res.data);
    },
    async getFilteredItems(categoryId: number, min_price?:number,max_price?:number,color?:string,size?:string,sort_by?:string,order?:string, page?:number) {
        return api.get(`/products/filtered?category=${categoryId}&min_price=${min_price}&max_price=${max_price}&color=${color}&size=${size}&sort_by=${sort_by}&order=${order}&page=${page}&limit=8`).then(res => res.data);
    },
    async getRelatedItems(productId: number) {
        return api.get(`/products/similar/${productId}`).then(res => res.data);
    },
    async getNewItems() {
        return api.get('/products/latest').then(res => res.data);
    },
    async deleteItem(id: number) {
        return api.delete(`/products/${id}`).then(res => res.data);
    },
    async addProduct(product: any) {
        return api.post('/products/', product).then(res => res.data);
    },
    async updateProduct(id:number,product: any) {
        return api.put(`/products/${id}`, product).then(res => res.data);
    }

}
export const categoryApi = {
    async getCategories() {
        return api.get('/categories/').then(res => res.data);
    },
    async addCategory(name: string) {
        return api.post('/categories/', {name: name}).then(res => res.data);
    },
    async deleteCategory(id: number) {
        return api.delete(`/categories/${id}`).then(res => res.data);
    },
    async updateCategory(id: number, name: string) {
        return api.put(`/categories/${id}`, {
            name: name
        }).then(res => res.data);
    }

}

export const likedApi = {
    async getLiked() {
        return api.get('/favorites/').then(res => res.data);
    },
    async like(id: number) {
        return api.post(`/favorites/add/${id}`).then(res => res.data);
    },
    async unlike(id: number) {
        return api.delete(`/favorites/remove/${id}`).then(res => res.data);
    }
}

export const searchApi = {
    async search(query: string, page: number) {
        return api.get(`/products/search?q=${query}&page=${page}&limit=8`).then(res => res.data);
    }
}

export const reviewApi = {
    async addReview(productId: number,  review_text:string, star_rating:number, reviewer_name:string, reviewer_email:string) {
        return api.post(`/reviews/add/${productId}`, 
            {
                review_text, 
                star_rating, 
                reviewer_name, 
                reviewer_email
            }).then(res => res.data);
        
    }
}
export const cartApi = {
    async getCart() {
        return api.get('/cart/').then(res => res.data);
    },
    async addToCart(product_id:number, color:string, size:string, quantity:number) {
        return api.post(`/cart/add/`, {product_id, color, size, quantity}).then(res => res.data);
    },
    async removeFromCart(product_id:number, color:string, size:string ) {
        return api.delete(`/cart/remove/${product_id}`, {data:{product_id,color: color, size}}).then(res => res.data);
    },
    async updateCart(product_id:number, color:string, size:string, quantity:number) {
        return api.put(`/cart/update/${product_id}`, {product_id,color,size,quantity}).then(res => res.data);
    },
    async clearCart() {
        return api.delete('/cart/clear').then(res => res.data);
    }
}
export const colorApi = {
    async getColors() {
        return api.get('/colors/').then(res => res.data);
    },
    async getSizes(){
        return api.get('/colors/sizes').then(res=>res.data)
    }
}

export const authApi = {
    async isAdmin() {
        return api.get('/auth/isAdmin').then(res => res.data);
    },
    async login(email: string, password: string) {
        return api.post('/auth/login', {email, password}).then(res => res.data);
    },
    async logout() {
        return api.post('/auth/logout').then(res => res.data);
    }
}
export const orderApi= {
    
    async makeOrder(order:OrderType){
        return api.post('/orders/guest/', {...order}).then(res=>res.data)
    },
    async getOrders(){
        return api.get('/orders').then(res=>res.data)
    },
    async deleteOrder(id:number){
        return api.delete(`/orders/${id}`).then(res=>res.data)
    },
    async updateOrder(id: number, singleOrder:any){ 
        return api.put(`/orders/${id}`,{...singleOrder}).then((res) => res.data)
    },
    async getOrderDetails(id:number){
        return api.get(`/orders/details/${id}`).then(res=>res.data)
    }
}