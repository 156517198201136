import { ItemType } from "../../types/item.types";
import { LikedItems } from "../../types/liked.types";
import { likedApi } from "../../api/api";

// Action types
const SET_LIKED_ITEMS = 'SET_LIKED_ITEMS';
const SET_IS_FETCHING = 'SET_IS_FETCHING';

// State and Action types
interface StateType {
  likedItems: LikedItems;
  isFetching: boolean;
}

const initialState: StateType = {
  likedItems: {
    favorites: []
  },
  isFetching: false
};

// Define action type with generics for payload type safety
interface ActionType<T = any> {
  type: string;
  payload: T;
}

// Reducer with fully typed payloads
export const likedReducer = (
  state: StateType = initialState,
  action: ActionType<LikedItems | boolean>
): StateType => {
  switch (action.type) {
    case SET_LIKED_ITEMS:
      return {
        ...state,
        likedItems: action.payload as LikedItems
      };
    case SET_IS_FETCHING:
      return {
        ...state,
        isFetching: action.payload as boolean
      };
    default:
      return state;
  }
};

// Action creators with explicit types
const setLikedItems = (likedItems: LikedItems): ActionType<LikedItems> => ({
  type: SET_LIKED_ITEMS,
  payload: likedItems
});

const setIsFetching = (isFetching: boolean): ActionType<boolean> => ({
  type: SET_IS_FETCHING,
  payload: isFetching
});

// Async actions with fully typed dispatch and return value
export const getLikedItems = () => async (
  dispatch: (action: ActionType<LikedItems | boolean>) => void
): Promise<void> => {
  dispatch(setIsFetching(true));
  try {
    const data: LikedItems = await likedApi.getLiked(); // Assuming likedApi.getLiked() returns LikedItems
    dispatch(setLikedItems(data));
  } finally {
    dispatch(setIsFetching(false));
  }
};

export const likeItem = (id: number) => async (
  dispatch: (action: any) => void
): Promise<void> => {
  dispatch(setIsFetching(true));
  try {
    await likedApi.like(id);
    dispatch(getLikedItems());
  } finally {
    dispatch(setIsFetching(false));
  }
};

export const unlikeItem = (id: number) => async (
  dispatch: (action: any) => void
): Promise<void> => {
  dispatch(setIsFetching(true));
  try {
    await likedApi.unlike(id); 
    dispatch(getLikedItems());
  } finally {
    dispatch(setIsFetching(false));
  }
};
