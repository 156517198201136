import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './ThanksForOrder.module.css';

const ThanksForOrder = () => {
    const navigate = useNavigate();

    useEffect(() => {
        const timeout = setTimeout(() => {
            navigate('/');
        }, 3000); // Redirect after 3 seconds

        return () => clearTimeout(timeout); // Cleanup on component unmount
    }, [navigate]);

    return (
        <div className={styles.fullScreen}>
            <div className={styles.thankYouContainer}>
                <div className={styles.checkMark}></div>
                <h2 className={styles.title}>Дякуємо за ваше замовлення!</h2>
              
            </div>
        </div>
    );
};

export default ThanksForOrder;
