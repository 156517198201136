import React, { useState, useCallback, useEffect } from 'react';
import Select, { StylesConfig, components } from 'react-select';
import { useDropzone } from 'react-dropzone';
import styles from './ColorSizeForm.module.css';
import { colorApi } from '../../../../../api/api';

interface ColorSizeFormProps {
  index: number;
  formData: { color: string; sizes: { size: string; stock: string }[]; image: File[] | null; image_url: any };
  onChange: (formData: { color: string; sizes: { size: string; stock: string }[]; image: File[] | null; image_url: any }) => void;
  errors: any;
  handleImageDelete: (url: string) => void;
}

interface ColorType {
  color_id: number;
  color: string;
  product_id: number;
  label: string;
  value: string;
}

const ColorSizeForm: React.FC<ColorSizeFormProps> = ({ index, formData, onChange, errors , handleImageDelete}) => {
  const [isAddColor, setIsAddColor] = useState(false);
  const [colors, setColors] = useState<ColorType[]>([]);
  const [isFetching, setIsFetching] = useState<boolean>(false);
  const [imageToDelete, setImageToDelete] = useState<string[]>([]);

  const deleteImage = (url: string) => {
    setImageToDelete((prev) => [...prev, url]); // Add the deleted URL to the array
    const updatedImages = formData.image_url[0].filter((image: any) => image.media_url !== url);
    onChange({ ...formData, image_url: [updatedImages] });
    handleImageDelete(url);
  };

  useEffect(() => {
    searchItems();
  }, []);


  const searchItems = async () => {
    try {
      const response = await colorApi.getColors();
      const colors: ColorType[] = response.colors
        .filter((color: ColorType) => color.color != null)
        .map((color: ColorType) => ({
          ...color,
          label: color.color,
          value: color.color,
        }));
      setColors(colors);
    } catch (error) {
      console.error(error);
    }
  };

  const handleColorChange = (selectedOption: ColorType | null) => {
    if (selectedOption) {
      onChange({ ...formData, color: selectedOption.value, image_url: formData.image_url });
    }
  };

  const handleSizeChange = (sizeIndex: number, name: 'size' | 'stock', value: string) => {
    errors[`size-stock-${index}`] = '';
    const updatedSizes = [...formData.sizes];
    updatedSizes[sizeIndex][name] = value;
    onChange({ ...formData, sizes: updatedSizes, image_url: formData.image_url });
  };

  const addSizeRow = () => {
    onChange({ ...formData, sizes: [...formData.sizes, { size: '', stock: '' }], image_url: formData.image_url });
  };

  const removeSizeRow = (sizeIndex: number) => {
    if (formData.sizes.length === 1) return;
    const updatedSizes = [...formData.sizes];
    updatedSizes.splice(sizeIndex, 1);
    onChange({ ...formData, sizes: updatedSizes, image_url: formData.image_url });
  };

  const handleDrop = useCallback(
    (acceptedFiles: File[]) => {
      errors[`image-${index}`] = '';
      if (acceptedFiles.length > 0) {
        onChange({ ...formData, image: acceptedFiles, image_url: formData.image_url });
      }
    },
    [formData, onChange]
  );

  const ColourOption = (props: any) => (
    <components.Option {...props}>
      <div>{props.data.color}</div>
    </components.Option>
  );

  const ColourSingleValue = (props: any) => (
    <components.SingleValue {...props}>
      <div>{props.data.color}</div>
    </components.SingleValue>
  );

  const customStyles: StylesConfig<ColorType, false> = {
    control: (base) => ({
      ...base,
      borderColor: '#ddd',
      borderRadius: '4px',
      padding: '2px',
    }),
    option: (base) => ({
      ...base,
      display: 'flex',
      alignItems: 'center',
      padding: '5px',
    }),
    singleValue: (base) => ({
      ...base,
      display: 'flex',
      alignItems: 'center',
      padding: '5px',
    }),
  };

  return (
    <div className={styles.addColorContainer}>
      <div className={styles.addColorFormGroup}>
        <div className={styles.buttonsContainer}>
          <button className={isAddColor ? styles.addColorBtn : styles.AddExistingColor} onClick={() => setIsAddColor(true)}>
            Додати новий
          </button>
          <button className={isAddColor ? styles.AddExistingColor : styles.addColorBtn} onClick={() => setIsAddColor(false)}>
            Існуючий колір
          </button>
        </div>

        {!isAddColor ? (
          <div className={styles.existingColor}>
            <label htmlFor="color" className={styles.addProductLabel}>
              Колір:
            </label>
            {isFetching ? (
              <p>Завантаження...</p>
            ) : (
              <Select
                options={colors}
                onChange={handleColorChange}
                value={colors.find((color) => color.value === formData.color) || null}
                className={styles.selectColorInput}
                styles={customStyles}
                components={{ Option: ColourOption, SingleValue: ColourSingleValue }}
              />
            )}
          </div>
        ) : (
          <div className={styles.addColor}>
            <label htmlFor="color" className={styles.addProductLabel}>
              Колір
            </label>
            <input
              type="text"
              value={formData.color}
              onChange={(e) =>
                handleColorChange({
                  value: e.target.value,
                  label: e.target.value,
                  color: e.target.value,
                  color_id: 0,
                  product_id: 0,
                })
              }
              className={styles.colorInput}
            />
          </div>
        )}
      </div>

      <div className={styles.addSizeFormGroup}>
        <label htmlFor="size" className={styles.addSizeLabel}>
          Розмір
        </label>
        <table className={styles.table}>
          <thead className={styles.head}>
            <tr>
              <th>Розмір</th>
              <th>Кількість</th>
            </tr>
          </thead>
          <tbody>
            {formData.sizes.map((size, sizeIndex) => (
              <tr key={sizeIndex}>
                <td className={styles.inputCell}>
                  <input
                    type="text"
                    value={size.size}
                    onChange={(e) => handleSizeChange(sizeIndex, 'size', e.target.value)}
                    className={styles.sizeInput}
                  />
                </td>
                <td className={styles.inputCell}>
                  <input
                    type="number"
                    value={size.stock}
                    onChange={(e) => handleSizeChange(sizeIndex, 'stock', e.target.value)}
                    className={styles.stockInput}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>

        <div className={styles.addRemoveButtons}>
          <button onClick={addSizeRow} className={styles.addSizeButton}>
            Додати розмір
          </button>
          <button onClick={() => removeSizeRow(formData.sizes.length - 1)} className={styles.removeSizeButton}>
            Видалити розмір
          </button>
        </div>
        {errors[`size-stock-${index}`] && <p className={styles.errorText}>{errors[`size-stock-${index}`]}</p>}
      </div>

      <div className={styles.addImageFormGroup}>
        <label htmlFor="image" className={styles.addProductLabel}>
          Зображення продукту
        </label>
        <Dropzone onDrop={handleDrop} file={formData.image} />
        {formData.image_url?.[0]?.length > 0 &&
          formData.image_url[0].map((url: any, index: number) => (
            <div key={index} className={styles.deleteContainerBtn}>
              <img src={url.media_url} alt="Preview" className={styles.imagePreview} />
              <button onClick={() => deleteImage(url.media_url)} className={styles.imageBtn}>
                Видалити
              </button>
            </div>
          ))}
        {errors[`image-${index}`] && <p className={styles.errorText}>{errors[`image-${index}`]}</p>}
      </div>
    </div>
  );
};

const Dropzone: React.FC<{ onDrop: (acceptedFiles: File[]) => void; file: File[] | null }> = ({ onDrop, file }) => {
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    multiple: true,
    accept: {
      'image/*': ['.png', '.jpg', '.jpeg'],
      'video/*': ['.mp4', '.avi'],
    },
  });

  return (
    <div {...getRootProps({ className: styles.dropzone })}>
      <input {...getInputProps()} />
      {isDragActive ? (
        <p>Перетягніть файл сюди ...</p>
      ) : file && file.length > 0 ? (
        <div>
          {file.map((f, index) => (
            <img key={index} src={URL.createObjectURL(f)} alt="Preview" className={styles.imagePreview} />
          ))}
          <div>
            {file.map((f, index) => (
              <p key={index}>{f.name}</p>
            ))}
          </div>
        </div>
      ) : (
        <p>Натисність сюди або перетягніть файл</p>
      )}
    </div>
  );
};

export default ColorSizeForm;
