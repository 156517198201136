import React from 'react'
import styles from './ItemsNotFound.module.css'
import { IoMdClose } from "react-icons/io";

const ItemsNotFound = () => {
  return (
    <div className={styles.container}>
        <IoMdClose className={styles.icon}></IoMdClose>
        <p className={styles.title}>Нічого не було знайдено</p>
    </div>
  )
}

export default ItemsNotFound
