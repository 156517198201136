// import React, { useState, useCallback, useEffect } from 'react';
// import Select from 'react-select';
// import { useDropzone } from 'react-dropzone';
// import styles from './ColorSizeForm.module.css';
// import { colorApi } from '../../../../../api/api';
// import axios from 'axios';
// import { error } from 'console';

// interface ColorSizeFormProps {
//   index: number;
//   formData: { color: string; sizes: { size: string; stock: string }[]; image: File | null };
//   onChange: (formData: { color: string; sizes: { size: string; stock: string }[]; image: File | null }) => void;
//   errors:any
// }
// interface ColorType {
//   color_id: number;
//   color: string;
//   product_id: number;
// }

// const ColorSizeForm: React.FC<ColorSizeFormProps> = ({ index, formData, onChange ,errors}) => {
//   const [isAddColor, setIsAddColor] = useState(false);
//   const [colors, setColors] = useState<ColorType[]>([]);
//   const [isFetching, setIsFetching] = useState<boolean>(false)


//   useEffect(() => {
//     searchItems();
//   }, [])
//   const searchItems = async () => {
//     try {

//       let response = await colorApi.getColors()
//       let colors: ColorType[] = response.colors
//       colors = colors.filter(color => color.color != null)
//       setColors(colors)

//     } catch (error) {
//       console.error(error)
//     }
//   }

//   const handleColorChange = (color: string) => {
//     onChange({ ...formData, color });
//   };



//   // Handle size change
//   const handleSizeChange = (sizeIndex: number, name: 'size' | 'stock', value: string) => {
//     //SET ERROR TO zero WHEN TYPING
//     errors[`size-stock-${index}`] = ''
//     const updatedSizes = [...formData.sizes];
//     updatedSizes[sizeIndex][name] = value;
//     onChange({ ...formData, sizes: updatedSizes });
//   };

//   // Add/remove size rows
//   const addSizeRow = () => {
//     onChange({ ...formData, sizes: [...formData.sizes, { size: '', stock: '' }] });
//   };

//   const removeSizeRow = (sizeIndex: number) => {
//     if (formData.sizes.length === 1) return;
//     const updatedSizes = [...formData.sizes];
//     updatedSizes.splice(sizeIndex, 1);
//     onChange({ ...formData, sizes: updatedSizes });
//   };

//   // Handle image drop
//   const handleDrop = useCallback((acceptedFiles: File[]) => {
//     errors[`image-${index}`] = ''
//     if (acceptedFiles.length > 0) {
//       onChange({ ...formData, image: acceptedFiles[0] });
//     }
//   }, [formData, onChange]);

//   return (
//     <div className={styles.addColorContainer}>
//       <div className={styles.addColorFormGroup}>
//         <div className={styles.buttonsContainer}>
//           <button
//             className={isAddColor ? styles.addColorBtn : styles.AddExistingColor}
//             onClick={() => setIsAddColor(true)}
//           >
//             Додати новий
//           </button>
//           <button
//             className={isAddColor ? styles.AddExistingColor : styles.addColorBtn}
//             onClick={() => setIsAddColor(false)}
//           >
//             Існуючий колір
//           </button>
//         </div>

//         {!isAddColor ? (
//           <div className={styles.existingColor}>
//             <label htmlFor="color" className={styles.addProductLabel}>
//               Колір:
//             </label>
//             {isFetching ? <p>Завантаження...</p> : (
//               <Select
//                 options={colors.map((color) => ({ value: color.color, label: color.color }))} // Map colors to Select options
//                 onChange={(option: any) => handleColorChange(option.value)}
//                 value={{ value: formData.color, label: formData.color }}
//                 className={styles.selectColorInput}
//               />
//             )}
//           </div>
//         ) : (
//           <div className={styles.addColor}>
//             <label htmlFor="color" className={styles.addProductLabel}>
//               Колір
//             </label>
//             <input
//               type="color"
//               value={formData.color}
//               onChange={(e) => handleColorChange(e.target.value)}
//               className={styles.colorInput}
//             />
//           </div>
//         )}
//       </div>

//       {/* Product Size addable table */}
//       <div className={styles.addSizeFormGroup}>
//         <label htmlFor="size" className={styles.addSizeLabel}>Розмір</label>
//         <table className={styles.table}>
//           <thead className={styles.head}>
//             <tr>
//               <th>Розмір</th>
//               <th>Кількість</th>
//             </tr>
//           </thead>
//           <tbody>
//             {formData.sizes.map((size, sizeIndex) => (
//               <tr key={sizeIndex}>
//                 <td className={styles.inputCell}>
//                   <input
//                     type="text"
//                     value={size.size}
//                     onChange={(e) => handleSizeChange(sizeIndex, 'size', e.target.value)}
//                     className={styles.sizeInput}
//                   />
//                 </td>
//                 <td className={styles.inputCell}>
//                   <input
//                     type="number"
//                     value={size.stock}
//                     onChange={(e) => handleSizeChange(sizeIndex, 'stock', e.target.value)}
//                     className={styles.stockInput}
//                   />
//                 </td>
//               </tr>
//             ))}
//           </tbody>
//         </table>

//         <div className={styles.addRemoveButtons}>
//           <button onClick={addSizeRow} className={styles.addSizeButton}>Додати розмір</button>
//           <button onClick={() => removeSizeRow(formData.sizes.length - 1)} className={styles.removeSizeButton}>Видалити розмір</button>
//         </div>
//         {errors[`size-stock-${index}`] && <p className={styles.errorText}>{errors[`size-stock-${index}`]}</p>}
//       </div>

//       {/* Dropzone for image upload */}
//       <div className={styles.addImageFormGroup}>
//         <label htmlFor="image" className={styles.addProductLabel}>Зображення продукту</label>
//         <Dropzone onDrop={handleDrop} file={formData.image} />
//         {errors[`image-${index}`] && <p className={styles.errorText}>{errors[`image-${index}`]}</p>}
//       </div>
      
//     </div>
//   );
// };

// const Dropzone: React.FC<{ onDrop: (acceptedFiles: File[]) => void; file: File | null }> = ({ onDrop, file }) => {
//   const { getRootProps, getInputProps, isDragActive } = useDropzone({
//     onDrop,
//     multiple: false, // Only allow one file at a time
//     accept: {
//       'image/*': ['.png', '.jpg', '.jpeg'],
//       'video/*': ['.mp4', '.avi'],
//     }, // Only accept image files
//   });

//   return (
//     <div {...getRootProps({ className: styles.dropzone })}>
//       <input {...getInputProps()} />
//       {isDragActive ? (
//         <p>Перетягніть файл сюди ...</p>
//       ) : file ? (
//         <div>
//           <img
//             src={URL.createObjectURL(file)} // Generate preview URL
//             alt="Preview"
//             className={styles.imagePreview} // Apply styling for preview
//           />
//           <p>{file.name}</p>
//         </div>
//       ) : (
//         <p>Натисність сюди або перетягніть файл</p>
//       )}
//     </div>
//   );
// };

// export default ColorSizeForm;
import React, { useState, useCallback, useEffect } from 'react';
import Select, { StylesConfig, components } from 'react-select';
import { useDropzone } from 'react-dropzone';
import styles from './ColorSizeForm.module.css';
import { colorApi } from '../../../../../api/api';

interface ColorSizeFormProps {
  index: number;
  formData: { color: string; sizes: { size: string; stock: string }[]; image: File[] | null };
  onChange: (formData: { color: string; sizes: { size: string; stock: string }[]; image: File[] | null }) => void;
  errors: any;
}

interface ColorType {
  color_id: number;
  color: string;
  product_id: number;
  label: string; // required for react-select
  value: string; // required for react-select
}

const ColorSizeForm: React.FC<ColorSizeFormProps> = ({ index, formData, onChange, errors }) => {
  const [isAddColor, setIsAddColor] = useState(false);
  const [colors, setColors] = useState<ColorType[]>([]);
  const [isFetching, setIsFetching] = useState<boolean>(false);

  useEffect(() => {
    searchItems();
  }, []);

  const searchItems = async () => {
    try {
      const response = await colorApi.getColors();
      const colors: ColorType[] = response.colors
        .filter((color: ColorType) => color.color != null)
        .map((color: ColorType) => ({
          ...color,
          label: color.color, // setting color text as label
          value: color.color, // setting color text as value
        }));
      setColors(colors);
    } catch (error) {
      console.error(error);
    }
  };

  const handleColorChange = (selectedOption: ColorType | null) => {
    if (selectedOption) {
      onChange({ ...formData, color: selectedOption.value });
    }
  };

  const handleSizeChange = (sizeIndex: number, name: 'size' | 'stock', value: string) => {
    errors[`size-stock-${index}`] = '';
    const updatedSizes = [...formData.sizes];
    updatedSizes[sizeIndex][name] = value;
    onChange({ ...formData, sizes: updatedSizes });
  };

  const addSizeRow = () => {
    onChange({ ...formData, sizes: [...formData.sizes, { size: '', stock: '' }] });
  };

  const removeSizeRow = (sizeIndex: number) => {
    if (formData.sizes.length === 1) return;
    const updatedSizes = [...formData.sizes];
    updatedSizes.splice(sizeIndex, 1);
    onChange({ ...formData, sizes: updatedSizes });
  };

  const handleDrop = useCallback(
    (acceptedFiles: File[]) => {
      errors[`image-${index}`] = '';
      if (acceptedFiles.length > 0) {
        onChange({ ...formData, image: acceptedFiles });
      }
    },
    [formData, onChange]
  );

  // Modify ColourOption and ColourSingleValue to display text only, without color preview
  const ColourOption = (props: any) => (
    <components.Option {...props}>
      <div>{props.data.color}</div>
    </components.Option>
  );

  const ColourSingleValue = (props: any) => (
    <components.SingleValue {...props}>
      <div>{props.data.color}</div>
    </components.SingleValue>
  );

  const customStyles: StylesConfig<ColorType, false> = {
    control: (base) => ({
      ...base,
      borderColor: '#ddd',
      borderRadius: '4px',
      padding: '2px',
    }),
    option: (base) => ({
      ...base,
      display: 'flex',
      alignItems: 'center',
      padding: '5px',
    }),
    singleValue: (base) => ({
      ...base,
      display: 'flex',
      alignItems: 'center',
      padding: '5px',
    }),
  };

  return (
    <div className={styles.addColorContainer}>
      <div className={styles.addColorFormGroup}>
        <div className={styles.buttonsContainer}>
          <button className={isAddColor ? styles.addColorBtn : styles.AddExistingColor} onClick={() => setIsAddColor(true)}>
            Додати новий
          </button>
          <button className={isAddColor ? styles.AddExistingColor : styles.addColorBtn} onClick={() => setIsAddColor(false)}>
            Існуючий колір
          </button>
        </div>

        {!isAddColor ? (
          <div className={styles.existingColor}>
            <label htmlFor="color" className={styles.addProductLabel}>
              Колір:
            </label>
            {isFetching ? (
              <p>Завантаження...</p>
            ) : (
              <Select
                options={colors}
                onChange={handleColorChange}
                value={colors.find((color) => color.value === formData.color) || null}
                className={styles.selectColorInput}
                styles={customStyles}
                components={{ Option: ColourOption, SingleValue: ColourSingleValue }}
              />
            )}
          </div>
        ) : (
          <div className={styles.addColor}>
            <label htmlFor="color" className={styles.addProductLabel}>
              Колір
            </label>
            <input
              type="text"
              value={formData.color}
              onChange={(e) => handleColorChange({ value: e.target.value, label: e.target.value, color: e.target.value, color_id: 0, product_id: 0 })}
              className={styles.colorInput}
            />
          </div>
        )}
      </div>

      {/* Product Size addable table */}
      <div className={styles.addSizeFormGroup}>
        <label htmlFor="size" className={styles.addSizeLabel}>Розмір</label>
        <table className={styles.table}>
          <thead className={styles.head}>
            <tr>
              <th>Розмір</th>
              <th>Кількість</th>
            </tr>
          </thead>
          <tbody>
            {formData.sizes.map((size, sizeIndex) => (
              <tr key={sizeIndex}>
                <td className={styles.inputCell}>
                  <input
                    type="text"
                    value={size.size}
                    onChange={(e) => handleSizeChange(sizeIndex, 'size', e.target.value)}
                    className={styles.sizeInput}
                  />
                </td>
                <td className={styles.inputCell}>
                  <input
                    type="number"
                    value={size.stock}
                    onChange={(e) => handleSizeChange(sizeIndex, 'stock', e.target.value)}
                    className={styles.stockInput}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>

        <div className={styles.addRemoveButtons}>
          <button onClick={addSizeRow} className={styles.addSizeButton}>Додати розмір</button>
          <button onClick={() => removeSizeRow(formData.sizes.length - 1)} className={styles.removeSizeButton}>Видалити розмір</button>
        </div>
        {errors[`size-stock-${index}`] && <p className={styles.errorText}>{errors[`size-stock-${index}`]}</p>}
      </div>

      {/* Dropzone for image upload */}
      <div className={styles.addImageFormGroup}>
        <label htmlFor="image" className={styles.addProductLabel}>Зображення продукту</label>
        <Dropzone onDrop={handleDrop} file={formData.image} />
        {errors[`image-${index}`] && <p className={styles.errorText}>{errors[`image-${index}`]}</p>}
      </div>
      
    </div>
  );
};


const Dropzone: React.FC<{ onDrop: (acceptedFiles: File[]) => void; file: File[] | null }> = ({ onDrop, file }) => {
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    multiple: true,
    accept: {
      'image/*': ['.png', '.jpg', '.jpeg'],
      'video/*': ['.mp4', '.avi'],
    },
  });

  return (
    <div {...getRootProps({ className: styles.dropzone })}>
      <input {...getInputProps()} />
      {isDragActive ? (
        <p>Перетягніть файл сюди ...</p>
      ) : file && file.length > 0 ? (
        <div>
        {
          file.map((f, index) => (
            <img
              key={index}
              src={URL.createObjectURL(f)}
              alt="Preview"
              className={styles.imagePreview}
            />
          ))
        }
          <div>
            {
              file.map((f, index) => (
                <p key={index}>{f.name}</p>
              ))

            }
            </div>
        </div>
      ) : (
        <p>Натисність сюди або перетягніть файл</p>
      )}
    </div>
  );
};

export default ColorSizeForm;
