// SearchPage.tsx
import { useEffect, useState } from 'react';
import styles from './SearchPage.module.css';
import { useSearchParams } from 'react-router-dom';
import { RootState } from '../../types/store.types';
import { searchItems as fetchSearchItems } from '../../redux/reducers/search-reducer';
import SkeletonLoader from '../UI/SkeletonLoader/SkeletonLoader';
import CategoryItem from '../UI/CategoryItem/CategoryItem';
import { searchApi } from '../../api/api';
import { SearchItem } from '../../types/search.types';
import { connect } from 'react-redux';
import { getLikedItems } from '../../redux/reducers/liked-reducer';
import { LikedItems } from '../../types/liked.types';
import Pagination from '../UI/Pagination/Pagination';
import ItemsNotFound from '../UI/ItemsNotFound/ItemsNotFound';
interface SearchPageProps {
    liked: LikedItems;
    isLikedFetching: boolean;
    getLikedItems: () => void;
}

const SearchPage = (props: SearchPageProps) => {
    const [searchParams] = useSearchParams();
    const [items, setItems] = useState<SearchItem[]>([]);
    const [isFetching, setIsFetching] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);

    const searchQuery = searchParams.get('q');

    useEffect(() => {
       if (searchQuery) {
           setCurrentPage(1); // Reset to first page on new search
           searchItems(1);
       }
    }, [searchQuery]);

    
    useEffect(()=>{
        window.scrollTo(0,0);
    },[currentPage])

    const searchItems = async (page: number) => {
        if (!searchQuery) return;
        
        setIsFetching(true);
        try {
            const response = await searchApi.search(searchQuery || '', page);
            setItems(response.products);
            setTotalPages(response.totalPages); // Assuming totalPages is returned from the API
        } finally {
            setIsFetching(false);
        }
    };

    const handlePageChange = (page: number) => {
        setCurrentPage(page);
        searchItems(page); // Fetch items for the new page
    };

    return (
        <main>
            <h2 className={styles.title}>
                Результати пошуку: "<span className={styles.result}>{searchQuery}</span>"
            </h2>
            <div className={styles.searchContainer}>
                {isFetching ? (
                    <SkeletonLoader length={10} />
                ) : items.length!==0?(
                    items.map((item) => (
                        <CategoryItem 
                            key={item.product_id} 
                            item={item} 
                            isLiked={props.liked.favorites.some(
                                (likedItem) => likedItem.product_id === item.product_id
                            )} 
                        />
                    ))
                ):<ItemsNotFound/>}
            </div>
            {totalPages > 1 && (
                <Pagination 
                    totalPages={totalPages} 
                    currentPage={currentPage} 
                    onPageChange={handlePageChange} 
                />
            )}
        </main>
    );
};

let mapStateToProps = (state: RootState) => ({
    liked: state.liked.likedItems,
    isLikedFetching: state.liked.isFetching,
});

let mapDispatchToProps = {
    getLikedItems,
};

export default connect(mapStateToProps, mapDispatchToProps)(SearchPage);
