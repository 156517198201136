// SkeletonLoader.js
import React from 'react';
import styles from './SkeletonLoader.module.css';

const SkeletonLoader = (props:{length:number}) => {
  return (
    <div className={styles.skeletonContainer}>
      {Array.from({ length: props.length }).map((_, index) => (
        <div key={index} className={styles.skeletonItem}></div>
      ))}
    </div>
  );
};

export default SkeletonLoader;
